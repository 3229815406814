const colors = {
    black: '#000000',
    black06Opacity: 'rgba(0, 0, 0, 0.6)',
    darkGray: '#929292',
    lightGray: '#F3F0F0',
    midGray: '#C4C4C4',
    lightBlue: '#C4C4C4',
    white: '#FFFFFF',
    whiteHalfOpacity: 'rgba(255, 255, 255, 0.5)',
    whiteLowOpacity: 'rgba(255, 255, 255, 0.3)',
    main: '#CB4700',
    warning: '#EB5757',
    lightWarning: 'rgba(235, 87, 87, 0.20)',
    orange: '#FF9700',
    red: '#FF0000',
    primary: '#5eafc7',
    primaryHalfOpacity: 'rgba(94, 175, 199, 0.5)',
    primaryLowOpacity: 'rgba(94, 175, 199, 0.3)',
    secondary: '#cccccc',
}

export default colors
