import { TextField } from '@mui/material'
import { AttributeNumberValue } from 'graphql/types'
import { AttributeValueEditProps } from './DataItemDetailsPanel'

export const AttributeNumberValueEdit = (props: AttributeValueEditProps) => {
    const { attribute, attributeValue, setAttributeValue } = props

    if (attributeValue.__typename !== 'AttributeNumberValue') return null
    if (!attribute || attribute.__typename !== 'AttributeNumber') return null

    const { value } = attributeValue

    const override = (values: Partial<AttributeNumberValue>) =>
        setAttributeValue({
            ...attributeValue,
            ...values,
        })

    return (
        <TextField
            label={attribute.common.name}
            value={value.toString()}
            type="number"
            onChange={(event) => override({ value: parseFloat(event.target.value) })}
        />
    )
}
