import DeleteIcon from '@mui/icons-material/Delete'
import {
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from '@mui/material'
import { cloneDeep } from 'lodash'
import { useState } from 'react'
import { useStoreActions, useStoreState } from 'store/hooks'
import { AddMode } from 'store/model'
import colors from 'utils/colors'

export interface AddElementPanelProps {
    displayBlockOptions: boolean
    closeAddDrawer: () => void
}

enum ElementType {
    PAGE,
    BLOCK,
    CLIPBOARD,
}

const AddElementPanel = (props: AddElementPanelProps): JSX.Element => {
    const { closeAddDrawer, displayBlockOptions } = props

    const confirmAddElement = useStoreActions((actions) => actions.model.confirmAddElement)
    const removeFromClipboard = useStoreActions((actions) => actions.model.removeFromClipboard)
    const setAddMode = useStoreActions((actions) => actions.model.setAddMode)
    const pageContentClipboard = useStoreState((state) => state.model.pageContentClipboard)
    const addMode = useStoreState((state) => state.model.addMode)
    const blockList = useStoreState((state) => state.model.blockList)
    const elementDefinitions = useStoreState((state) => state.model.elementDefinitionList)

    const [elementType, setElementType] = useState<ElementType>(ElementType.PAGE)

    const handleElementTypeChange = (event: React.MouseEvent<HTMLElement>, elementType: ElementType) => {
        setElementType(elementType)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stack direction={'row'} sx={{ m: 2 }} justifyContent="space-between">
                <Typography variant="h6">Add</Typography>
                <FormControl size="small">
                    <InputLabel id="add-behaviour-label">Behaviour</InputLabel>
                    <Select
                        label="Behaviour"
                        labelId="add-behaviour-label"
                        id="add-behaviour"
                        value={addMode}
                        onChange={(e) => {
                            setAddMode(e.target.value as AddMode)
                        }}
                    >
                        <MenuItem value={AddMode.ADD_SINGLE}>Add single</MenuItem>
                        <MenuItem value={AddMode.ADD_AND_EDIT}>Add + edit</MenuItem>
                        <MenuItem value={AddMode.ADD_MULTIPLE}>Add multiple</MenuItem>
                    </Select>
                </FormControl>
            </Stack>
            <Grid sx={{ m: 2 }} justifyContent="space-between">
                <ToggleButtonGroup color="primary" value={elementType} exclusive onChange={handleElementTypeChange}>
                    <ToggleButton value={ElementType.PAGE}>Element</ToggleButton>
                    {displayBlockOptions && <ToggleButton value={ElementType.BLOCK}>Block</ToggleButton>}
                    <ToggleButton value={ElementType.CLIPBOARD}>Clipboard</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <List>
                <Divider color={colors.midGray} />

                {elementType === ElementType.PAGE &&
                    elementDefinitions.map((element) => (
                        <>
                            <ListItemButton
                                key={element.identifier}
                                onClick={() => {
                                    confirmAddElement({
                                        type: element.identifier,
                                        initialData: cloneDeep(element.initialData),
                                    })
                                }}
                            >
                                <ListItemText primary={element.name} />
                            </ListItemButton>
                            <Divider color={colors.midGray} />
                        </>
                    ))}
                {elementType === ElementType.BLOCK &&
                    displayBlockOptions &&
                    blockList.map((block) => (
                        <>
                            <ListItemButton
                                key={block.id}
                                onClick={() => {
                                    confirmAddElement({ type: '__qbd_block', initialData: { __qbd_blockId: block.id } })
                                }}
                            >
                                <ListItemText primary={block.currentVersion.name} />
                            </ListItemButton>
                            <Divider color={colors.midGray} />
                        </>
                    ))}
                {elementType === ElementType.CLIPBOARD &&
                    pageContentClipboard.map((element) => (
                        <>
                            <ListItemButton
                                key={element.id}
                                onClick={() => {
                                    confirmAddElement({ type: element.type, initialData: element.data })
                                }}
                            >
                                <ListItemText primary={element.type} />
                                <ListItemSecondaryAction>
                                    <Tooltip title="Remove from clipboard" placement={'right'}>
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                removeFromClipboard(element)
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItemButton>
                            <Divider color={colors.midGray} />
                        </>
                    ))}
                <Box sx={{ width: '100%', textAlign: 'right', padding: '1em', boxSizing: 'border-box' }}>
                    <Button variant="contained" onClick={closeAddDrawer}>
                        Close
                    </Button>
                </Box>
            </List>
        </Box>
    )
}

export default AddElementPanel
