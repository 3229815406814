import { createStore } from 'easy-peasy' // 👈 import

import model, { Model } from './model'

export interface StoreModel {
    model: Model
}

const storeModel: StoreModel = {
    model,
}

const store = createStore(storeModel) // 👈 create our store

export default store
