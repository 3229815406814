import { FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material'
import { AttributeSelectionValue } from 'graphql/types'
import { AttributeValueEditProps } from './DataItemDetailsPanel'

export const AttributeSelectionValueEdit = (props: AttributeValueEditProps) => {
    const { attribute, attributeValue, setAttributeValue } = props

    if (attributeValue.__typename !== 'AttributeSelectionValue') return null
    if (!attribute || attribute.__typename !== 'AttributeSelection') return null

    const { value } = attributeValue

    const override = (values: Partial<AttributeSelectionValue>) =>
        setAttributeValue({
            ...attributeValue,
            ...values,
        })

    return (
        <FormControl fullWidth variant={'filled'}>
            <InputLabel>{attribute.common.name}</InputLabel>
            <Select
                label={attribute.common.name}
                value={value}
                onChange={(event) => override({ value: event.target.value })}
            >
                {attribute.options.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        <Tooltip title={item.description}>
                            <Typography width={'100%'}>{item.name}</Typography>
                        </Tooltip>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
