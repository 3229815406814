import { Delete, Edit, FileCopy, History, Search, Settings, SettingsBackupRestore } from '@mui/icons-material'
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import { format } from 'date-fns'
import { Block, BlockVersion } from 'graphql/types'
import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'

export interface BlockDetailsPanelrops {
    onEditBlock: (block: Block) => void
    onEditSettings: (blockID: string) => void
    onDuplicate: (blockID: string) => void
    onDelete: (blockID: string) => void
    showBlockVersion: (blockVersionID: string) => void
    reactivateBlockVersion: (blockVersionID: string) => void
    block: Block
}

const timeFormat = 'dd.MM.yyyy HH:mm'

const getLastPublished = (block: Block): string => {
    const blockHistoryVersions = cloneDeep(block.historyVersions)
    for (const blockVersion of blockHistoryVersions.sort((a, b) => b.updatedAt - a.updatedAt)) {
        //TODO: move state in blockversion
        if (blockVersion.state === 'PUBLISHED') {
            return format(new Date(blockVersion.updatedAt), timeFormat)
        }
    }
    return '-'
}

const renderVersionEntry = (
    bv: BlockVersion,
    isCurrentVersion: boolean,
    showBlockVersion: (blockVersionID: string) => void,
    reactivateBlockVersion: (blockVersionID: string) => void,
) => {
    return (
        <>
            <Box display="flex" flexDirection="row" flex={1}>
                <Grid container flexDirection={'row'} alignItems={'center'}>
                    <Grid xs={10} item>
                        <Typography>{format(new Date(bv.updatedAt), timeFormat)}</Typography>
                        <Typography>{bv.updatedBy.name}</Typography>
                    </Grid>
                    <Grid xs={2} item flexDirection="column">
                        <Tooltip title={'Show'} placement="right">
                            <Button
                                variant="text"
                                onClick={() => {
                                    showBlockVersion(bv.id)
                                }}
                            >
                                <Search />
                            </Button>
                        </Tooltip>
                        {!isCurrentVersion ? (
                            <Tooltip title={'Reactivate'} placement="right">
                                <Button
                                    variant="text"
                                    onClick={() => {
                                        reactivateBlockVersion(bv.id)
                                    }}
                                >
                                    <SettingsBackupRestore />
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button style={{ opacity: 0 }} disabled>
                                <SettingsBackupRestore />
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Divider />
        </>
    )
}

const BlockDetailsPanel = (props: BlockDetailsPanelrops): JSX.Element => {
    const { onEditBlock, onEditSettings, onDuplicate, onDelete, block } = props

    const lastPublished = getLastPublished(block)

    const [displayBlockVersion, setDisplayBlockVersion] = useState<boolean>(false)

    const showBlockVersion = (v: string) => {
        console.log('showBlockVersion', v)
    }
    const reactivateBlockVersion = (v: string) => {
        console.log('reactivateBlockVersion', v)
    }

    useEffect(() => {
        setDisplayBlockVersion(false)
    }, [block])

    return (
        <Grid container direction="column" p={2} spacing={1}>
            <Grid container item direction="row" alignItems={'center'}>
                <Grid item xs={10}>
                    <Typography variant="h6">{block.currentVersion.name}</Typography>
                </Grid>
                <Grid item xs={2} justifyItems={'flex-end'}>
                    <Tooltip title="Edit block" placement="right">
                        <IconButton
                            onClick={() => {
                                onEditBlock(block)
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container item direction="row">
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell scope="row">Last change</TableCell>
                                <TableCell align="right">
                                    {format(new Date(block.currentVersion.updatedAt), timeFormat)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">Changed by</TableCell>
                                <TableCell align="right">
                                    <Tooltip title={block.currentVersion.updatedBy.name} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '148px',
                                            }}
                                        >
                                            {block.currentVersion.updatedBy.name}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">Last publish</TableCell>
                                <TableCell align="right">{lastPublished}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">Status</TableCell>
                                <TableCell align="right">{block.currentVersion.state}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid container item direction="row">
                <Grid item xs={10} display="flex" alignContent="end">
                    <Tooltip title="Block settings">
                        <IconButton
                            onClick={() => {
                                onEditSettings(block.id)
                            }}
                        >
                            <Settings />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Duplicate block">
                        <IconButton
                            onClick={() => {
                                onDuplicate(block.id)
                            }}
                        >
                            <FileCopy />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete block">
                        <IconButton
                            onClick={() => {
                                onDelete(block.id)
                            }}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Version History'}>
                        <IconButton
                            onClick={() => {
                                setDisplayBlockVersion(!displayBlockVersion)
                            }}
                        >
                            <History />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            {displayBlockVersion && (
                <Grid container item direction="column">
                    <Grid item xs={12}>
                        <Typography variant="h5">Versions</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {renderVersionEntry(
                            block.currentVersion,
                            true,
                            (v) => showBlockVersion(v),
                            (v) => reactivateBlockVersion(v),
                        )}
                    </Grid>
                    {block.historyVersions.slice(1).map((blockVersion: BlockVersion, index: number) => {
                        return (
                            <React.Fragment key={blockVersion.id}>
                                {index > 0 && <Divider />}
                                {renderVersionEntry(blockVersion, false, showBlockVersion, reactivateBlockVersion)}
                            </React.Fragment>
                        )
                    })}
                </Grid>
            )}
        </Grid>
    )
}

export default BlockDetailsPanel
