import { gql } from '@apollo/client'

export const LOAD_ME = gql`
    query loadMe {
        loadMe {
            id
            name
            defaultWebsiteID
        }
        loadWebsites {
            id
            name
        }
    }
`
