import { Checkbox, Divider, FormControlLabel, TextField } from '@mui/material'
import { AttributeNumber } from 'graphql/types'
import { AttributeEditProps } from './AttributeCommonEdit'

export const AttributeNumberEdit = (props: AttributeEditProps) => {
    const { selectedAttribute, setSelectedAttribute } = props

    if (selectedAttribute.__typename !== 'AttributeNumber') return null

    const { defaultValue, integer, max, min } = selectedAttribute

    const override = (values: Partial<AttributeNumber>) =>
        setSelectedAttribute({
            ...selectedAttribute,
            ...values,
        })

    return (
        <>
            <Divider />

            <TextField
                label={'Default value'}
                defaultValue={defaultValue}
                type="number"
                onChange={(event) => override({ defaultValue: parseInt(event.target.value) })}
            />
            <FormControlLabel control={<Checkbox value={integer} />} label="Integer values only" />
            <TextField
                label={'Minimum value'}
                defaultValue={min}
                type="number"
                onChange={(event) => override({ min: parseInt(event.target.value) })}
            />
            <TextField
                label={'Maximum value'}
                defaultValue={max}
                type="number"
                onChange={(event) => override({ max: parseInt(event.target.value) })}
            />
        </>
    )
}
