import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import { WebsiteInput } from 'graphql/types'
import { Controller, useForm } from 'react-hook-form'
import { useStoreActions, useStoreState } from 'store/hooks'

interface WebsiteSettingsInput {
    Label: string
    PreviewURL: string
    ElementDefinitions: string
}

export const GeneralSettingsTab = (): JSX.Element => {
    const website = useStoreState((state) => state.model.selectedWebsite)
    const websiteSave = useStoreActions((actions) => actions.model.websiteSave)

    const { handleSubmit, control } = useForm<WebsiteSettingsInput>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {
            Label: website?.name,
            PreviewURL: website?.previewURL,
            ElementDefinitions: website?.elementDefinitions,
        },
    })

    const saveWebsiteData = () => {
        if (website) {
            handleSubmit((data: WebsiteSettingsInput) => {
                const ws: WebsiteInput = {
                    id: website.id,
                    name: data.Label,
                    elementDefinitions: data.ElementDefinitions,
                    previewURL: data.PreviewURL,
                    createdAt: website.createdAt,
                }
                websiteSave(ws)
            })()
        }
    }

    return (
        <Box display="flex" flexDirection="column" p={2} maxWidth={800} marginX={'auto'}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">Website Settings</Typography>
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="Label"
                    control={control}
                    rules={{
                        required: 'Please enter a name',
                        maxLength: { value: 150, message: 'The name may only be 150 characters long' },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label="Label"
                            fullWidth={true}
                            {...field}
                            inputProps={{ maxLength: 150 }}
                            helperText={fieldState.error?.message ?? ''}
                        />
                    )}
                />
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="PreviewURL"
                    control={control}
                    rules={{
                        required: 'Please enter a PreviewURL',
                        maxLength: { value: 150, message: 'The PreviewURL may only be 150 characters long' },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label="PreviewURL"
                            fullWidth={true}
                            {...field}
                            inputProps={{ maxLength: 150 }}
                            helperText={fieldState.error?.message ?? ''}
                        />
                    )}
                />
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="ElementDefinitions"
                    control={control}
                    render={({ field }) => {
                        return (
                            <TextField
                                multiline
                                minRows={4}
                                maxRows={20}
                                {...field}
                                fullWidth={true}
                                label="Element Definitions"
                                variant={'filled'}
                            />
                        )
                    }}
                />
            </Grid>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" onClick={saveWebsiteData}>
                    Save
                </Button>
            </Stack>
        </Box>
    )
}
