import { Checkbox, Divider, FormControlLabel, TextField } from '@mui/material'
import { AttributeText } from 'graphql/types'
import { AttributeEditProps } from './AttributeCommonEdit'

export const AttributeTextEdit = (props: AttributeEditProps) => {
    const { selectedAttribute, setSelectedAttribute } = props

    if (selectedAttribute.__typename !== 'AttributeText') return null

    const { defaultValue, multiLine } = selectedAttribute

    const override = (values: Partial<AttributeText>) =>
        setSelectedAttribute({
            ...selectedAttribute,
            ...values,
        })

    return (
        <>
            <Divider />

            <TextField
                label={'Default value'}
                defaultValue={defaultValue}
                onChange={(event) => override({ defaultValue: event.target.value })}
            />
            <FormControlLabel control={<Checkbox value={multiLine ?? false} />} label="Multiple lines" />
        </>
    )
}
