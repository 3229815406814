import { Divider, List, ListItemButton, ListItemText } from '@mui/material'
import { UseDataItemPageReturn } from 'pages/DataItemPage/UseDataItemPage'
import { Fragment } from 'react'
import colors from 'utils/colors'

const DataItemPanel = (props: UseDataItemPageReturn): JSX.Element => {
    const { dataTypes, onClickType, selectedType } = props

    return (
        <List>
            <Divider color={colors.midGray} />

            {dataTypes.map((item) => (
                <Fragment key={item.id}>
                    <ListItemButton selected={item.id === selectedType?.id} onClick={() => onClickType(item)}>
                        <ListItemText primary={item.name} />
                    </ListItemButton>
                    <Divider color={colors.midGray} />
                </Fragment>
            ))}
        </List>
    )
}

export default DataItemPanel
