import gql from 'graphql-tag'

export const DATA_ITEM_CREATE = gql`
    mutation dataItemCreate($dataTypeID: ID!, $input: DataItemInput!) {
        dataItemCreate(dataTypeID: $dataTypeID, input: $input)
    }
`

export const DATA_ITEM_UPDATE = gql`
    mutation dataItemUpdate($input: DataItemInput!) {
        dataItemUpdate(input: $input)
    }
`

export const DATA_ITEM_DELETE = gql`
    mutation dataItemDelete($id: ID!) {
        dataItemDelete(id: $id)
    }
`

export const DATA_ITEM_BATCH_SET_PUBLISHED = gql`
    mutation dataItemBatchSetPublished($input: [DataItemPublishInput!]!) {
        dataItemBatchSetPublished(input: $input)
    }
`
