import { Checkbox, FormControlLabel } from '@mui/material'
import { AttributeBooleanValue } from 'graphql/types'
import { AttributeValueEditProps } from './DataItemDetailsPanel'

export const AttributeBooleanValueEdit = (props: AttributeValueEditProps) => {
    const { attribute, attributeValue, setAttributeValue } = props

    if (attributeValue.__typename !== 'AttributeBooleanValue') return null
    if (!attribute || attribute.__typename !== 'AttributeBoolean') return null

    const { value } = attributeValue

    const override = (values: Partial<AttributeBooleanValue>) =>
        setAttributeValue({
            ...attributeValue,
            ...values,
        })

    return (
        <FormControlLabel
            control={<Checkbox checked={value} onChange={(event) => override({ value: event.target.checked })} />}
            label={attribute.common.name}
        />
    )
}
