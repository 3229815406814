export enum FieldType {
    TEXT = 'text',
    INFO_TEXT = 'infoText',
    HEADLINE = 'headline',
    TOGGLE = 'toggle',
    SELECT = 'select',
    LINK = 'link',
    MEDIA = 'media',
    COLLECTION = 'collection',
}

export enum SelectControlType {
    DROPDOWN = 'dropdown',
    RADIO = 'radio',
}

export type FieldDefinition =
    | {
          type: FieldType.TEXT
          label: string
          valueName: string
          multiLine?: boolean
          lines?: number
      }
    | {
          type: FieldType.INFO_TEXT
          label: string
      }
    | {
          type: FieldType.LINK
          label: string
          valueName: string
          disableInternal?: boolean
          disableExternal?: boolean
          disableFile?: boolean
      }
    | {
          type: FieldType.MEDIA
          label: string
          valueName: string
      }
    | {
          type: FieldType.HEADLINE
          text: string
      }
    | {
          type: FieldType.TOGGLE
          label: string
          valueName: string
      }
    | {
          type: FieldType.SELECT
          label: string
          valueName: string
          control: SelectControlType
          options: { value: string; label: string }[]
      }
    | {
          type: FieldType.COLLECTION
          title: string
          itemTitle: string
          valueName: string
          min?: number
          max?: number
          fields: FieldDefinition[]
      }

export type ElementDefinition = {
    name: string
    identifier: string
    initialData: any
    fields: FieldDefinition[]
}
