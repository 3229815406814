import { CMSTab } from 'components/CMSHeader/CMSHeader'
import { SnackbarType } from 'components/GeneralSnackbar/GeneralSnackbar'
import { CollectionItemData } from 'drawerPanels/EditElementPanel/DialogElements/CollectionControl'
import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy'
import { ElementDefinition } from 'elementDefinitions'
import { ASSET_DELETE } from 'graphql/mutations/assetDelete'
import { FOLDER_CREATE } from 'graphql/mutations/assetsFolderCreate'
import { FOLDER_DELETE } from 'graphql/mutations/assetsFolderDelete'
import { FOLDER_UPDATE } from 'graphql/mutations/assetsFolderUpdate'
import { ASSET_UPDATE } from 'graphql/mutations/assetUpdate'
import { ASSET_UPLOAD } from 'graphql/mutations/assetUpload'
import { BLOCK_CREATE } from 'graphql/mutations/blockCreate'
import { BLOCK_DELETE } from 'graphql/mutations/blockDelete'
import { BLOCK_DUPLICATE } from 'graphql/mutations/blockDuplicate'
import { BLOCK_SAVE } from 'graphql/mutations/blockSave'
import { DEACTIVATE_PAGE } from 'graphql/mutations/pageDeactivate'
import { DELETE_PAGE } from 'graphql/mutations/pageDelete'
import { DUPLICATE_PAGE } from 'graphql/mutations/pageDuplicate'
import { SAVE_PAGE } from 'graphql/mutations/pageSave'
import { PAGE_TREE_ORDER_SAVE } from 'graphql/mutations/pageTreeOrderSave'
import { WEBSITE_CREATE } from 'graphql/mutations/websiteCreate'
import { WEBSITE_SAVE } from 'graphql/mutations/websiteSave'
import { LOAD_BLOCK } from 'graphql/queries/loadBlock'
import { LOAD_ME } from 'graphql/queries/loadMe'
import { LOAD_PAGE } from 'graphql/queries/loadPage'
import { LOAD_WEBSITE_DATA } from 'graphql/queries/loadWebsiteData'
import {
    Asset,
    AssetFolder,
    AssetUpdateInput,
    AssetUploadInput,
    Block,
    BlockCreateInput,
    BlockSaveInput,
    BlockVersion,
    CreatePageInput,
    DuplicatePageInput,
    ErrorConfig,
    FolderCreateInput,
    LoggedInUser,
    OrderItemInput,
    Page,
    PageVersion,
    PublishItem,
    SavePageInput,
    State,
    Website,
    WebsiteInput,
} from 'graphql/types'
import cloneDeep from 'lodash/cloneDeep'
import { fetchData } from 'utils/fetchData'
import { resolvePagelURL } from 'utils/linkResolver'
import { makeRandomID } from 'utils/makeRandomID'
import { getParsedPageElements } from 'utils/parseElementContent'
import { executeOnItem, findElement, updateIDs } from 'utils/treeUtils'
import {
    AddElementPosition,
    AssetSimplified,
    Breakpoint,
    CurrentlyEditingData,
    GeneralSnackbarConfig,
    LanguageVersion,
    LinkData,
    MediaData,
    PageElement,
    UnpublishedItem,
    UnpublishedItemState,
    UnpublishedItemType,
} from 'utils/types'
import { CREATE_PAGE } from '../graphql/mutations/pageCreate'
import { LATEST_BLOCK_VERSION } from '../graphql/queries/getLatesBlockVersion'
import { LATEST_PAGE_VERSION } from '../graphql/queries/getLatesPageVersion'
import { PUBLISH_PAGES } from './../graphql/mutations/publishItems'
import { LOAD_ASSETS } from './../graphql/queries/loadAssets'
import { FolderUpdateInput } from './../graphql/types'
import { BlockData, WebsiteOption } from './../utils/types'
export enum AddMode {
    ADD_SINGLE,
    ADD_AND_EDIT,
    ADD_MULTIPLE,
}

export interface Model {
    loggedInUser?: LoggedInUser
    selectedHeaderTab: CMSTab
    pageContents: PageElement[] // The tree of page elements making up the content of the current page
    currentlyEditingData?: CurrentlyEditingData
    currentlyEditingElement?: PageElement
    currentlySelectedPage?: Page
    currentlySelectedBlock?: Block
    addElementPosition?: AddElementPosition
    pageContentClipboard: PageElement[]
    selectedWebsite?: Website
    pageList: Page[]
    blockList: Block[]
    breakpoints: Breakpoint[]
    assets: Asset[]
    folders: AssetFolder[]
    expanded: string[]
    currentLanguageVersion?: LanguageVersion
    generalSnackbarConfig: GeneralSnackbarConfig
    serverError?: ErrorConfig
    savingCurrentVersionDisabled: boolean
    websiteList: WebsiteOption[]

    addMode: AddMode

    // ------------------------- COMPUTED -------------------------
    editedPageContents: Computed<Model, PageElement[]>
    blockData: Computed<Model, BlockData[]>
    tagList: Computed<Model, string[]>
    assetsSimplified: Computed<Model, AssetSimplified[]>
    unpublishedItems: Computed<Model, UnpublishedItem[]>
    languageVersionList: Computed<Model, LanguageVersion[]>
    elementDefinitionList: Computed<Model, ElementDefinition[]>
    // ------------------------- ACTIONS -------------------------
    storeDataAndOpenAddElementDrawer: Action<Model, { elementID: string; before: boolean }>
    cancelAddElement: Action<Model>
    confirmAddElement: Action<Model, { type: string; initialData: any }>
    deleteElement: Action<Model, string>
    duplicateElement: Action<Model, string>
    copyToClipboard: Action<Model, string>
    cutToClipboard: Action<Model, string>
    removeFromClipboard: Action<Model, PageElement>
    editElement: Action<Model, string>
    changeElementValue: Action<
        Model,
        { valueName: string; newValue: string | LinkData | MediaData | CollectionItemData[] }
    >
    cancelEditElement: Action<Model>
    toggleElementDeactivated: Action<Model, string>
    setExpanded: Action<Model, string[]>
    setAddMode: Action<Model, AddMode>
    setHeaderTab: Action<Model, CMSTab>
    updateWebsite: Action<Model, Website>
    addWebsiteToList: Action<Model, Website>
    saveUserData: Action<
        Model,
        {
            user: LoggedInUser
            websiteList: WebsiteOption[]
        }
    >
    saveWebsiteData: Action<
        Model,
        {
            website: Website
            pageList: Page[]
            blockList: Block[]
            page?: Page
            block?: Block
            assets: Asset[]
            folders: AssetFolder[]
        }
    >
    setCurrentlySelectedPage: Action<Model, Page | undefined>
    startEditingPage: Action<
        Model,
        { currentlyEditingData: CurrentlyEditingData | undefined; pageElementContents: PageElement[] }
    >
    setPagesToDeactivate: Action<Model, string>
    updatePageData: Action<Model, { pageData: Page; updateCurrentlySelectedPage: boolean }>
    removePagesFromPageList: Action<Model, string>
    addPageToPageList: Action<Model, Page>
    addBlockToBlockList: Action<Model, Block>
    removeBlockFromBlockList: Action<Model, string>
    setCurrentlySelectedBlock: Action<Model, Block | undefined>
    updateBlockData: Action<Model, Block>
    setAssetsData: Action<Model, { assets: Asset[]; folders: AssetFolder[] }>
    addFolderToList: Action<Model, AssetFolder>
    removeFolderFromList: Action<Model, string>
    updateFolderData: Action<Model, AssetFolder>
    addAssetToList: Action<Model, Asset>
    removeAssetFromList: Action<Model, string>
    updateAssetData: Action<Model, Asset>
    updateDataAfterPublish: Action<Model, PublishItem[]>
    setCurrentLanguageVersion: Action<Model, string>
    updateCurrentLanguageVersion: Action<Model, string>
    removeCurrentLanguageVersion: Action<Model, string>
    clearCurrentLanguageVersion: Action<Model>
    configureSnackbar: Action<Model, GeneralSnackbarConfig>
    setSavingCurrentVersionDisabled: Action<Model, boolean>
    handleVersionChange: Action<Model, { data: Page | Block; type: string }>
    configureServerError: Action<Model, ErrorConfig | undefined>

    // ------------------------- THUNKS -------------------------
    // save: Thunk<Model>
    loadCMSData: Thunk<Model>
    loadPage: Thunk<Model, string>
    savePage: Thunk<Model, SavePageInput>
    savePageOrder: Thunk<Model, OrderItemInput[]>
    deactivatePage: Thunk<Model, string>
    deletePage: Thunk<Model, string>
    duplicatePage: Thunk<Model, DuplicatePageInput>
    createPage: Thunk<Model, CreatePageInput>

    loadBlock: Thunk<Model, string>
    createBlock: Thunk<Model, BlockCreateInput>
    saveBlock: Thunk<Model, BlockSaveInput>
    deleteBlock: Thunk<Model, string>
    duplicateBlock: Thunk<Model, string>

    loadAssets: Thunk<Model, string>
    createAssetFolder: Thunk<Model, FolderCreateInput>
    deleteAssetFolder: Thunk<Model, string>
    updateAssetFolder: Thunk<Model, FolderUpdateInput>

    uploadAsset: Thunk<Model, AssetUploadInput>
    deleteAsset: Thunk<Model, string>
    updateAsset: Thunk<Model, AssetUpdateInput>

    publishItems: Thunk<Model, PublishItem[]>

    websiteSave: Thunk<Model, WebsiteInput>
    websiteCreate: Thunk<Model, WebsiteInput>

    getLatestBlockVersion: Thunk<Model, string>
    getLatestPageVersion: Thunk<Model, string>

    setSelectedWebsite: Thunk<Model, string>
}

const model: Model = {
    // Data
    pageContentClipboard: [],
    pageContents: [],
    assets: [],
    folders: [],
    pageList: [],
    blockList: [],
    expanded: [],
    websiteList: [],
    addMode: AddMode.ADD_AND_EDIT,
    selectedHeaderTab: CMSTab.PAGES,
    breakpoints: [
        { identifier: 'xs', name: 'xs', editorCssWidth: '380px', fromWidthPixels: 0 },
        { identifier: 'sm', name: 'sm', editorCssWidth: '620px', fromWidthPixels: 600 },
        { identifier: 'md', name: 'md', editorCssWidth: '1024px', fromWidthPixels: 900 },
        { identifier: 'lg', name: 'lg', editorCssWidth: '1280px', fromWidthPixels: 1200 },
        { identifier: 'xl', name: 'xl', editorCssWidth: '100%', fromWidthPixels: 1536 },
    ],
    generalSnackbarConfig: {
        isOpen: false,
        message: '',
        type: SnackbarType.WARNING,
    },
    savingCurrentVersionDisabled: false,

    // Simply store the target position for an add. This triggers the selection dialog
    storeDataAndOpenAddElementDrawer: action((state, payload) => {
        state.addElementPosition = payload
    }),

    // Cancel the add element dialog by removing the target position
    cancelAddElement: action((state) => {
        state.addElementPosition = undefined
    }),

    // Add a page element
    confirmAddElement: action((state, { type, initialData }) => {
        const element = {
            id: makeRandomID(12),
            type: type,
            deactivated: false,
            data: initialData,
        }
        let before = false
        if (state && state.addElementPosition !== undefined) {
            if (state.addElementPosition.elementID != undefined) {
                before = state.addElementPosition.before
                let { elementID } = state.addElementPosition
                let regionIndex = -1
                if (elementID && elementID.indexOf('-') > -1) {
                    regionIndex = parseInt(elementID.split('-')[1])
                    elementID = elementID.split('-')[0]
                }

                executeOnItem(state.pageContents, elementID, (pe, list) => {
                    // if this is not adressing a region
                    if (regionIndex === -1) {
                        let index = list.indexOf(pe)
                        if (!before) index++ // if after then add one to the index
                        list.splice(index, 0, element)
                    } else if (pe.data.regions) {
                        pe.data.regions[regionIndex].splice(0, 0, element)
                    }
                })
            } else {
                state.pageContents.push(element)
            }
            if (state.addMode === AddMode.ADD_AND_EDIT) {
                state.currentlyEditingElement = element
                state.addElementPosition = undefined
            } else if (state.addMode === AddMode.ADD_SINGLE) {
                state.addElementPosition = undefined
            } else if (state.addMode === AddMode.ADD_MULTIPLE && !before) {
                state.addElementPosition = { elementID: element.id, before: false }
            }
        }
    }),

    // Delete a page element
    deleteElement: action((state, id) => {
        executeOnItem(state.pageContents, id, (pe, list) => {
            list.splice(list.indexOf(pe), 1)
        })
    }),

    // Start editing a page element by id
    editElement: action((state, id) => {
        const elementToEdit = findElement(state.pageContents, id)
        if (elementToEdit && elementToEdit.type !== '__qbd_block') state.currentlyEditingElement = elementToEdit
    }),

    // Edit the value in the pageElement that is currently being edited
    changeElementValue: action((state, { valueName, newValue }) => {
        if (state.currentlyEditingElement) {
            state.currentlyEditingElement.data[valueName] = newValue
        }
    }),

    // Cancel the editing of the element
    cancelEditElement: action((state) => {
        state.currentlyEditingElement = undefined
    }),

    // Copy an element to the clipboard
    copyToClipboard: action((state, id) => {
        let elementToCopy = findElement(state.pageContents, id)
        if (elementToCopy) {
            elementToCopy = cloneDeep(elementToCopy)
            state.pageContentClipboard.push(elementToCopy)
        }
    }),

    // Cut an element to the clipboard
    cutToClipboard: action((state, id) => {
        executeOnItem(state.pageContents, id, (pe, list) => {
            const removeds = list.splice(list.indexOf(pe), 1)
            if (removeds.length > 0) state.pageContentClipboard.push(removeds[0])
        })
    }),

    // Duplicate a page element
    duplicateElement: action((state, id) => {
        executeOnItem(state.pageContents, id, (pe, list) => {
            const copiedPE = cloneDeep(pe) // clone page element
            updateIDs(copiedPE) // all IDs must be replaced with new ones also in regions
            list.splice(list.indexOf(pe), 0, copiedPE)
        })
    }),

    // Remove element from the clipboard
    removeFromClipboard: action((state, element) => {
        const index = state.pageContentClipboard.findIndex((item) => item.id === element.id)
        state.pageContentClipboard.splice(index, 1)
    }),

    // Toggle deactivation for an element by id
    toggleElementDeactivated: action((state, id) => {
        const element = findElement(state.pageContents, id)
        if (element) element.deactivated = !element.deactivated
    }),

    // Set the list of pageIDs that are expanded
    setExpanded: action((state, ids) => {
        state.expanded = ids
    }),

    // Set the state whether items should get edited on adding
    setAddMode: action((state, addMode) => {
        state.addMode = addMode
    }),

    // Set which current tab was clicked
    setHeaderTab: action((state, selectedTab) => {
        state.selectedHeaderTab = selectedTab
    }),

    updateWebsite: action((state, website) => {
        state.selectedWebsite = website
    }),

    addWebsiteToList: action((state, website) => {
        state.websiteList.push(website)
    }),

    // set the data after loading the user
    saveUserData: action((state, { user, websiteList }) => {
        state.loggedInUser = user
        state.websiteList = websiteList
    }),

    // Set the data after loading website and user
    saveWebsiteData: action((state, { website, pageList, blockList, page, block, assets, folders }) => {
        state.selectedWebsite = website
        state.assets = assets
        state.folders = folders
        state.pageList = pageList.sort(function (a: Page, b: Page) {
            return a.sortIndex - b.sortIndex
        })
        state.blockList = blockList.sort(function (a: Block, b: Block) {
            if (a.currentVersion.name < b.currentVersion.name) {
                return -1
            }
            if (a.currentVersion.name > b.currentVersion.name) {
                return 1
            }
            return 0
        })
        state.currentlySelectedPage = page
        state.currentlySelectedBlock = block
    }),

    // Set the content of a page after editing it
    startEditingPage: action((state, { currentlyEditingData, pageElementContents }) => {
        state.pageContents = pageElementContents
        state.currentlyEditingData = currentlyEditingData
    }),

    // Set the currently selected page
    setCurrentlySelectedPage: action((state, page) => {
        state.currentlySelectedPage = page
    }),

    // Set the given page to deactivate with all her subpages
    setPagesToDeactivate: action((state, pageID) => {
        //check if page list and the currently selected page is undefined
        if (state.currentlySelectedPage) {
            //create array of string to collect all page ids to go through the page tree and deactivate these connected pages
            const idsToDeacticate = [pageID]
            state.pageList.forEach((page: Page) => {
                for (const pageID of idsToDeacticate) {
                    if (page.id === pageID || page.parentPageIdentifier === pageID) {
                        page.deactivated = !page.deactivated
                        idsToDeacticate.push(page.id)
                        break
                    }
                }
            })
            state.currentlySelectedPage.deactivated = !state.currentlySelectedPage.deactivated
        }
    }),

    // action for updating a page in the page list and if the param is set also the currently selected page
    updatePageData: action((state, { pageData, updateCurrentlySelectedPage }) => {
        if (updateCurrentlySelectedPage) state.currentlySelectedPage = pageData
        const page = state.pageList.find((page: Page) => page.id === pageData.id)
        if (page) page.currentVersion = pageData.currentVersion
    }),

    // add a freshly new created page to the page list
    addPageToPageList: action((state, page) => {
        state.pageList.forEach((p: Page) => {
            if (p.sortIndex >= page.sortIndex) {
                p.sortIndex++
            }
        })
        state.pageList.push(page)
        state.pageList.sort(function (a: Page, b: Page) {
            return a.sortIndex - b.sortIndex
        })
        state.currentlySelectedPage = page
    }),

    // remove the page with subpages from page list
    removePagesFromPageList: action((state, pageID) => {
        const idsToDelete = [pageID]
        state.pageList = state.pageList.filter((page: Page) => {
            for (const pageID of idsToDelete) {
                if (page.id === pageID || page.parentPageIdentifier === pageID) {
                    idsToDelete.push(page.id)
                    return false
                }
            }
            return true
        })
        state.pageList.forEach((p: Page, index: number) => {
            p.sortIndex = index
        })
        state.currentlySelectedPage = undefined
    }),

    // Set the currently selected block
    setCurrentlySelectedBlock: action((state, block) => {
        state.currentlySelectedBlock = block
    }),

    // add a freshly new created block to the block list
    addBlockToBlockList: action((state, block) => {
        state.blockList.push(block)
        state.blockList.sort(function (a: Block, b: Block) {
            if (a.currentVersion.name < b.currentVersion.name) {
                return -1
            }
            if (a.currentVersion.name > b.currentVersion.name) {
                return 1
            }
            return 0
        })
        state.currentlySelectedBlock = block
    }),

    // remove the block with subpages from block list
    removeBlockFromBlockList: action((state, blockID) => {
        const blockIndex = state.blockList.findIndex((block: Block) => block.id === blockID)
        if (blockIndex > -1) {
            state.blockList.splice(blockIndex, 1)
        }
    }),

    // action for updating a block in the block list
    updateBlockData: action((state, blockData) => {
        const block = state.blockList.find((block: Block) => block.id === blockData.id)
        if (block) {
            block.currentVersion = blockData.currentVersion
            block.identifier = blockData.identifier
        }
    }),

    // updateBlockData: action((state, { blockData, updateCurrentlySelectedBlock }) => {
    //     if (updateCurrentlySelectedBlock) state.currentlySelectedBlock = blockData
    //     const block = state.pageList.find((block: Block) => block.id === blockData.id)
    //     if (block) page.currentVersion = blockData.currentVersion
    // }),

    // set the loaded assets folder and files
    setAssetsData: action((state, { assets, folders }) => {
        state.assets = assets
        state.folders = folders
    }),

    // add new folder to the folder list
    addFolderToList: action((state, folder) => {
        state.folders.push(folder)
    }),

    // remove folder from the folder list
    removeFolderFromList: action((state, folderID) => {
        const folderToDelete = [folderID]
        //go through the folder and remove the folder with all his subfolders
        state.folders = state.folders.filter((folder: AssetFolder) => {
            for (const foID of folderToDelete) {
                if (folder.id === foID || folder.parentFolder === foID) {
                    folderToDelete.push(folder.id)
                    return false
                }
            }
            return true
        })

        //go through the files list and remove all files which were in the deleted folders
        state.assets = state.assets.filter((asset: Asset) => {
            for (const foID of folderToDelete) {
                if (asset.folder === foID) {
                    return false
                }
            }
            return true
        })
    }),

    // update folder data  in the list
    updateFolderData: action((state, folderData) => {
        const folderIndex = state.folders.findIndex((folder: AssetFolder) => folder.id === folderData.id)
        state.folders[folderIndex] = folderData
    }),

    // add new file to the file list
    addAssetToList: action((state, asset) => {
        state.assets.push(asset)
    }),

    // remove file from the file list
    removeAssetFromList: action((state, fileID) => {
        //go through the files list and remove the file which was deleted
        state.assets = state.assets.filter((asset: Asset) => {
            if (asset.id === fileID) {
                return false
            }
            return true
        })
    }),

    // update file data in the file list
    updateAssetData: action((state, asset) => {
        const fileIndex = state.assets.findIndex((file: Asset) => file.id === asset.id)
        state.assets[fileIndex] = asset
    }),

    // update the page list and currently selected page after publishing item
    updateDataAfterPublish: action((state, publishItemList) => {
        //go through the publish item list and update the data
        publishItemList.forEach((item: PublishItem) => {
            if (item.type === 'page') {
                // update currently selected page
                if (state.currentlySelectedPage) {
                    const currentVersion = state.currentlySelectedPage.currentVersion
                    if (state.currentlySelectedPage.historyVersions) {
                        state.currentlySelectedPage.historyVersions =
                            state.currentlySelectedPage.historyVersions.filter((v) => v.state === State.PUBLISHED)
                        state.currentlySelectedPage.historyVersions.push(currentVersion)
                    }
                    state.currentlySelectedPage.historyVersions.sort((a, b) => {
                        return b.updatedAt - a.updatedAt
                    })
                    state.currentlySelectedPage.currentVersion.state = State.PUBLISHED
                    state.currentlySelectedPage.currentVersion.updatedAt = new Date().getTime()
                    state.currentlySelectedPage.currentVersion.updatedBy = {
                        id: state.loggedInUser?.id ?? '',
                        name: state.loggedInUser?.name ?? '',
                    }
                }

                //update data in page tree
                const pageIndex = state.pageList.findIndex((page: Page) => page.id === item.itemID)
                const currentVersion = state.pageList[pageIndex].currentVersion
                if (state.pageList[pageIndex].historyVersions) {
                    state.pageList[pageIndex].historyVersions = state.pageList[pageIndex].historyVersions.filter(
                        (v) => v.state !== State.PUBLISHED,
                    )
                    state.pageList[pageIndex].historyVersions.push(currentVersion)
                }
                state.pageList[pageIndex].currentVersion.state = State.PUBLISHED
                state.pageList[pageIndex].currentVersion.updatedAt = new Date().getTime()
                state.pageList[pageIndex].currentVersion.updatedBy = {
                    id: state.loggedInUser?.id ?? '',
                    name: state.loggedInUser?.name ?? '',
                }
            } else if (item.type === 'block') {
                // update currently selected block
                if (state.currentlySelectedBlock) {
                    const currentVersion = state.currentlySelectedBlock.currentVersion
                    if (state.currentlySelectedBlock.historyVersions) {
                        state.currentlySelectedBlock.historyVersions =
                            state.currentlySelectedBlock.historyVersions.filter((v) => v.state === State.PUBLISHED)
                        state.currentlySelectedBlock.historyVersions.push(currentVersion)
                        state.currentlySelectedBlock.historyVersions.sort((a, b) => {
                            return b.updatedAt - a.updatedAt
                        })
                        state.currentlySelectedBlock.currentVersion.state = State.PUBLISHED
                        state.currentlySelectedBlock.currentVersion.updatedAt = new Date().getTime()
                        state.currentlySelectedBlock.currentVersion.updatedBy = {
                            id: state.loggedInUser?.id ?? '',
                            name: state.loggedInUser?.name ?? '',
                        }
                    }
                }

                //update data in block tree
                const blockIndex = state.blockList.findIndex((block: Block) => block.id === item.itemID)
                const currentVersion = state.blockList[blockIndex].currentVersion
                if (state.blockList[blockIndex].historyVersions) {
                    state.blockList[blockIndex].historyVersions = state.blockList[blockIndex].historyVersions.filter(
                        (v) => v.state !== State.PUBLISHED,
                    )
                    state.blockList[blockIndex].historyVersions.push(currentVersion)
                }
                state.blockList[blockIndex].currentVersion.state = State.PUBLISHED
                state.blockList[blockIndex].currentVersion.updatedAt = new Date().getTime()
                state.blockList[blockIndex].currentVersion.updatedBy = {
                    id: state.loggedInUser?.id ?? '',
                    name: state.loggedInUser?.name ?? '',
                }
            }
        })
    }),

    // clear the current language version
    clearCurrentLanguageVersion: action((state) => {
        state.currentLanguageVersion = undefined
    }),

    // a action which we are able to configue the snackbar. If it should be displayed. What message should be displayed and the type of message.
    configureSnackbar: action((state, generalSnackbarConfig) => {
        state.generalSnackbarConfig = generalSnackbarConfig
    }),

    // setting if the saving button is disabled
    setSavingCurrentVersionDisabled: action((state, disabled) => {
        state.savingCurrentVersionDisabled = disabled
    }),

    // is handling the change of a version and set the depending information
    handleVersionChange: action((state, { data, type }) => {
        if (type === 'page' && state.currentlySelectedPage) {
            const pageData = data as Page
            if (state.currentlySelectedPage.currentVersion.ID !== pageData.currentVersion.ID) {
                state.savingCurrentVersionDisabled = true
                state.generalSnackbarConfig = {
                    isOpen: true,
                    message: pageData.currentVersion.updatedBy.name + ' has edited this page',
                    type: SnackbarType.WARNING,
                }
            }
        } else if (type === 'block' && state.currentlySelectedBlock) {
            const blockData = data as Block
            if (state.currentlySelectedBlock.currentVersion.id !== blockData.currentVersion.id) {
                state.savingCurrentVersionDisabled = true
                state.generalSnackbarConfig = {
                    isOpen: true,
                    message: blockData.currentVersion.updatedBy.name + ' has edited this block',
                    type: SnackbarType.WARNING,
                }
            }
        }
    }),

    //configure the server error for displaying a dialog
    configureServerError: action((state, serverError) => {
        state.serverError = serverError
    }),

    // set the current language version. If there is no language version then a new one will be created
    setCurrentLanguageVersion: action((state, pageId) => {
        // get the current page and check if it's undefined
        const currentPage = state.pageList.find((p: Page) => p.id === pageId)
        if (!currentPage) return

        //find a matching language version
        const languageVersion = state.languageVersionList.find(
            (lv: LanguageVersion) => lv.translationID === currentPage.currentVersion.pageSettings.translationID,
        )

        if (languageVersion) {
            // remove from the linked page the current page
            languageVersion.linkedLanguagePages = languageVersion.linkedLanguagePages.filter(
                (lp) => lp.pageId !== pageId,
            )
            state.currentLanguageVersion = languageVersion
        } else {
            // create new languave version for this page. With a unique id
            state.currentLanguageVersion = {
                translationID: new Date().getTime() + makeRandomID(12),
                linkedLanguagePages: [],
            }
        }
    }),

    // add new entry to the current language version
    updateCurrentLanguageVersion: action((state, pageId) => {
        // get the current page and check if it's undefined or is the same as the currently selected page
        const currentPage = state.pageList.find((p: Page) => p.id === pageId)
        if (!currentPage || currentPage.id === state.currentlySelectedPage?.id) return

        // check if the page is already in the language version
        if (state.currentLanguageVersion?.linkedLanguagePages.find((lp) => lp.pageId === pageId)) return

        // check if the page does belong to a language version
        if (
            currentPage.currentVersion.pageSettings.translationID &&
            currentPage.currentVersion.pageSettings.translationID.length > 0
        ) {
            //find a matching language version
            const languageVersion = state.languageVersionList.find(
                (lv: LanguageVersion) => lv.translationID === currentPage.currentVersion.pageSettings.translationID,
            )
            //TODO add dialog to ask if the user wants to add overwrite them
            // check if the language version has linked pates
            if (languageVersion && languageVersion.linkedLanguagePages.length > 0) {
                state.currentLanguageVersion?.linkedLanguagePages.push(...languageVersion.linkedLanguagePages)
                // alert('Diese Seite hat andere Sprachversionen. Wollen Sie diese trotzdem hinzufügen?')
                // return
            }
            // check if the page doesn't belong to a language version and then add it
        } else if (
            !currentPage.currentVersion.pageSettings.translationID ||
            currentPage.currentVersion.pageSettings.translationID.length === 0
        ) {
            state.currentLanguageVersion?.linkedLanguagePages.push({
                pageId: currentPage.id,
                language: currentPage.currentVersion.pageSettings.language,
                url: resolvePagelURL(currentPage.id, state.pageList, false),
            })
        }
    }),

    // remove a entry from the current language version
    removeCurrentLanguageVersion: action((state, pageId) => {
        if (state.currentLanguageVersion) {
            // get the index form the page which should get delete
            const index = state.currentLanguageVersion.linkedLanguagePages.findIndex((p) => p.pageId === pageId)
            if (index !== -1) {
                state.currentLanguageVersion.linkedLanguagePages.splice(index, 1)
            }
            // if the linked pages are empty we set the whole current language version to undefined
            if (state.currentLanguageVersion.linkedLanguagePages.length === 0) {
                state.currentLanguageVersion = undefined
            }
        }
    }),

    // Compute the edited pageElements by replacing the original with the edited one
    editedPageContents: computed((state) => {
        const id = state.currentlyEditingElement?.id
        if (id && state.currentlyEditingElement) {
            const element = findElement(state.pageContents, id)
            element.data = state.currentlyEditingElement.data
        }
        return state.pageContents
    }),

    // Compute the block data from the block list
    blockData: computed((state) => {
        return state.blockList.map((block: Block) => {
            return {
                id: block.id,
                identifier: block.identifier,
                pageElements: getParsedPageElements(block),
                createdAt: block.createdAt,
                updatedAt: 0, //TODO: add maybe updatedAt to GraphQL Block? Or can we remove it
            }
        })
    }),

    // Compute the tag list from the assets folder and files
    tagList: computed((state) => {
        const tagList: string[] = []

        state.assets.forEach((asset: Asset) => {
            if (asset.tags) {
                tagList.push(...asset.tags)
            }
        })
        state.folders.forEach((folder: AssetFolder) => {
            if (folder.tags) {
                tagList.push(...folder.tags)
            }
        })

        return tagList
    }),

    // Compute the assets simplified list from the assets
    assetsSimplified: computed((state) => {
        return state.assets.map((asset: Asset) => {
            return {
                id: asset.id,
                label: asset.name,
                type: asset.type,
                url: asset.url,
            }
        })
    }),
    // Compute a list of unpuglished pages and block
    unpublishedItems: computed((state) => {
        const unpublishedItems: UnpublishedItem[] = []

        state.blockList.forEach((block: Block) => {
            if (block.currentVersion.state === State.PUBLISHED) return
            let status = UnpublishedItemState.UNPUBLISHED
            if (
                block.historyVersions &&
                block.historyVersions.find((bv: BlockVersion) => bv.state === State.PUBLISHED)
            ) {
                status = UnpublishedItemState.EDIT
            }

            unpublishedItems.push({
                id: block.id,
                type: UnpublishedItemType.BLOCK,
                label: block.currentVersion.name,
                state: status,
            })
        })

        state.pageList.forEach((page: Page) => {
            if (page.currentVersion.state === State.PUBLISHED) return
            let status = UnpublishedItemState.UNPUBLISHED
            if (page.historyVersions && page.historyVersions.find((pv: PageVersion) => pv.state === State.PUBLISHED)) {
                status = UnpublishedItemState.EDIT
            }

            unpublishedItems.push({
                id: page.id,
                type: UnpublishedItemType.PAGE,
                label: page.currentVersion.pageSettings.htmlTitle,
                state: status,
                parentPageId: page.parentPageIdentifier,
                url: resolvePagelURL(page.id, state.pageList, false),
            })
        })

        return unpublishedItems
    }),
    // Compute a list of all the language version of this website
    languageVersionList: computed((state) => {
        const languageVersions: LanguageVersion[] = []
        // go through all the pages
        state.pageList.forEach((page: Page) => {
            // check if translationID is not set. If the page doesn't have a translationID, we can skip this page.
            if (
                !page.currentVersion.pageSettings.translationID ||
                page.currentVersion.pageSettings.translationID.length === 0
            ) {
                return
            }

            //check if translationID already exit in the array
            const languageVersionExist = languageVersions.find(
                (lv: LanguageVersion) => lv.translationID === page.currentVersion.pageSettings.translationID,
            )
            if (languageVersionExist !== undefined) return

            const languageVersionEntry: LanguageVersion = {
                translationID: page.currentVersion.pageSettings.translationID,
                linkedLanguagePages: [],
            }

            // collect all the pages with the same translation id
            state.pageList.forEach((p: Page) => {
                if (p.currentVersion.pageSettings.translationID === languageVersionEntry.translationID) {
                    languageVersionEntry.linkedLanguagePages.push({
                        pageId: p.id,
                        language: p.currentVersion.pageSettings.language,
                        url: resolvePagelURL(p.id, state.pageList, false),
                    })
                }
            })

            languageVersions.push(languageVersionEntry)
        })

        return languageVersions
    }),

    // take the elemendefiniton from a website and convert it back to elementdefinition type
    elementDefinitionList: computed((state) => {
        let list: ElementDefinition[] = []

        if (state.selectedWebsite && state.selectedWebsite.elementDefinitions.trim().length > 3) {
            list = JSON.parse(state.selectedWebsite.elementDefinitions)
        }

        return list
    }),

    // Thunk for loading all necessary data for the CMS
    loadCMSData: thunk(async (actions) => {
        const meResponse = await fetchData(LOAD_ME, {})
        if (meResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }
        const websiteResponse = await fetchData(LOAD_WEBSITE_DATA, { website: meResponse.data.loadMe.defaultWebsiteID })
        if (websiteResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        let firstPage: Page | undefined = undefined
        if (websiteResponse.data.loadPages.length > 0) {
            firstPage = websiteResponse.data.loadPage
        }
        let firstBlock: Block | undefined = undefined
        if (websiteResponse.data.loadBlocks.length > 0) {
            firstBlock = websiteResponse.data.loadBlock
        }

        actions.saveUserData({ user: meResponse.data.loadMe, websiteList: meResponse.data.loadWebsites })
        actions.saveWebsiteData({
            website: websiteResponse.data.loadWebsite,
            pageList: websiteResponse.data.loadPages,
            blockList: websiteResponse.data.loadBlocks,
            page: firstPage,
            block: firstBlock,
            assets: websiteResponse.data.assets.assets,
            folders: websiteResponse.data.assets.folders,
        })
    }),

    // Thunk for loading a page by id
    loadPage: thunk(async (actions, pageID) => {
        const pageResponse = await fetchData(LOAD_PAGE, {
            pageID: pageID,
        })
        if (pageResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.setCurrentlySelectedPage(pageResponse.data.loadPage)
    }),

    // Thunk for loading a block by id
    loadBlock: thunk(async (actions, blockID) => {
        const blockResponse = await fetchData(LOAD_BLOCK, {
            blockID: blockID,
        })
        if (blockResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.setCurrentlySelectedBlock(blockResponse.data.loadBlock)
    }),

    // Thunk for saving content on a page
    savePage: thunk(async (actions, input) => {
        const pageResponse = await fetchData(SAVE_PAGE, { input })
        if (pageResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.updatePageData({ pageData: pageResponse.data.savePage.page, updateCurrentlySelectedPage: true })
        pageResponse.data.savePage.linkedPages.forEach((page: Page) => {
            actions.updatePageData({ pageData: page, updateCurrentlySelectedPage: false })
        })
        actions.clearCurrentLanguageVersion()
    }),

    // Thunk for createing a new page
    createPage: thunk(async (actions, input) => {
        const pageResponse = await fetchData(CREATE_PAGE, { input })
        if (pageResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.addPageToPageList(pageResponse.data.createPage)
    }),

    // Thunk for deactivate a page by id
    deactivatePage: thunk(async (actions, pageID) => {
        const pageResponse = await fetchData(DEACTIVATE_PAGE, {
            pageID: pageID,
        })
        if (pageResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.setPagesToDeactivate(pageID)
    }),

    // Thunk for delete a page by id
    deletePage: thunk(async (actions, pageID) => {
        const pageResponse = await fetchData(DELETE_PAGE, {
            pageID: pageID,
        })
        if (pageResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.removePagesFromPageList(pageID)
    }),

    // Thunk for duplicate a page by id
    duplicatePage: thunk(async (actions, input) => {
        const params: DuplicatePageInput = { pageID: input.pageID }
        const pageResponse = await fetchData(DUPLICATE_PAGE, { input: params })
        if (pageResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.addPageToPageList(pageResponse.data.duplicatePage)
    }),

    //Thunk for saving the new page tree order
    savePageOrder: thunk(async (actions, input) => {
        const res = await fetchData(PAGE_TREE_ORDER_SAVE, { input: input })
        if (res.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        //TODO: improve here later and sort it on the client for now load the server state
        // loadPageTree from server
        actions.loadCMSData()
    }),

    //Thunk for creating a block
    createBlock: thunk(async (actions, input) => {
        const blockResponse = await fetchData(BLOCK_CREATE, { input })
        if (blockResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.addBlockToBlockList(blockResponse.data.blockCreate)
    }),

    //Thunk for saving changes on a block
    saveBlock: thunk(async (actions, input) => {
        const blockResponse = await fetchData(BLOCK_SAVE, { input })
        if (blockResponse.errors) {
            if (blockResponse.errors[0].message === 'BlockSave: identifier already exists') {
                actions.configureServerError({
                    title: 'Identifier already exists',
                    content: 'The identifier you used, is already used in another block. Please use a different one.',
                })
            } else {
                actions.configureServerError({
                    title: 'Server Error',
                    content: 'A error happened. Please reload the page.',
                })
            }
            return
        }

        actions.updateBlockData(blockResponse.data.blockSave)
    }),

    //Thunk for deleting a block
    deleteBlock: thunk(async (actions, blockID) => {
        //TODO we only need to pass one id
        const blockResponse = await fetchData(BLOCK_DELETE, {
            ids: [blockID],
        })

        if (blockResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.removeBlockFromBlockList(blockID)
    }),

    //Thunk for duplicating a block
    duplicateBlock: thunk(async (actions, blockID) => {
        const blockResponse = await fetchData(BLOCK_DUPLICATE, {
            blockID: blockID,
        })

        if (blockResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.addBlockToBlockList(blockResponse.data.blockDuplicate)
    }),

    // Thunk for load all assets
    loadAssets: thunk(async (actions, websiteID) => {
        const assetsResponse = await fetchData(LOAD_ASSETS, {
            websiteID,
        })
        if (assetsResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.setAssetsData({
            assets: assetsResponse.data.loadAssets.assets,
            folders: assetsResponse.data.loadAssets.folders,
        })
    }),

    // Thunk for creating a new asset folder
    createAssetFolder: thunk(async (actions, input) => {
        const assetFolderResponse = await fetchData(FOLDER_CREATE, { input })
        if (assetFolderResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.addFolderToList(assetFolderResponse.data.folderCreate)
    }),

    // Thunk for deleteing a folder from the assets list
    deleteAssetFolder: thunk(async (actions, folderID) => {
        const assetFolderResponse = await fetchData(FOLDER_DELETE, { id: folderID })
        if (assetFolderResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.removeFolderFromList(folderID)
    }),

    // Thunk for updating a folder data
    updateAssetFolder: thunk(async (actions, input) => {
        const assetFolderResponse = await fetchData(FOLDER_UPDATE, { input })
        if (assetFolderResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.updateFolderData(assetFolderResponse.data.folderUpdate)
    }),

    // Thunk for uploading a file
    uploadAsset: thunk(async (actions, input) => {
        const fileResponse = await fetchData(ASSET_UPLOAD, { input })
        if (fileResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.addAssetToList(fileResponse.data.assetUpload)
    }),

    // Thunk for deleting an file
    deleteAsset: thunk(async (actions, fileID) => {
        const fileResponse = await fetchData(ASSET_DELETE, { id: fileID })
        if (fileResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.removeAssetFromList(fileID)
    }),

    // Thunk for updating file meta data
    updateAsset: thunk(async (actions, input) => {
        const fileResponse = await fetchData(ASSET_UPDATE, { input })
        if (fileResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.updateAssetData(fileResponse.data.assetUpdate)
    }),

    //Thunk for publish the selected item
    publishItems: thunk(async (actions, publishItemList) => {
        const response = await fetchData(PUBLISH_PAGES, { input: publishItemList })
        if (response.errors || response.data.publishPages.statusCode !== 200) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.updateDataAfterPublish(publishItemList)
    }),

    //Thunk for updating the details for a website
    websiteSave: thunk(async (actions, input) => {
        const response = await fetchData(WEBSITE_SAVE, { input })
        if (response.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.updateWebsite(response.data.websiteSave)
    }),

    //Thunk for creating a website
    websiteCreate: thunk(async (actions, input) => {
        const response = await fetchData(WEBSITE_CREATE, { input })
        if (response.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.addWebsiteToList(response.data.websiteCreate)
        actions.saveWebsiteData({
            website: response.data.websiteCreate,
            pageList: [],
            blockList: [],
            page: undefined,
            assets: [],
            folders: [],
        })
    }),

    //Thunk for getting the latest version of a block
    getLatestBlockVersion: thunk(async (actions, blockID) => {
        const response = await fetchData(LATEST_BLOCK_VERSION, { blockID: blockID })
        if (response.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.handleVersionChange({ data: response.data.loadBlock, type: 'block' })
    }),

    //Thunk for getting latest version of page
    getLatestPageVersion: thunk(async (actions, pageID) => {
        const response = await fetchData(LATEST_PAGE_VERSION, { pageID: pageID })
        if (response.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        actions.handleVersionChange({ data: response.data.loadPage, type: 'page' })
    }),

    //Thunk for loading the selected website
    setSelectedWebsite: thunk(async (actions, websiteID) => {
        const websiteResponse = await fetchData(LOAD_WEBSITE_DATA, {
            website: websiteID,
        })
        if (websiteResponse.errors) {
            actions.configureServerError({
                title: 'Server Error',
                content: 'A error happened. Please reload the page.',
            })
            return
        }

        let firstPage: Page | undefined = undefined
        if (websiteResponse.data.loadPages.length > 0) {
            const pageResponse = await fetchData(LOAD_PAGE, {
                pageID: websiteResponse.data.loadPages[0].id,
            })
            if (pageResponse.errors) {
                actions.configureServerError({
                    title: 'Server Error',
                    content: 'A error happened. Please reload the page.',
                })
                return
            }
            firstPage = pageResponse.data.loadPage
        }

        actions.saveWebsiteData({
            website: websiteResponse.data.loadWebsite,
            pageList: websiteResponse.data.loadPages,
            blockList: websiteResponse.data.loadBlocks,
            page: firstPage,
            assets: websiteResponse.data.assets.assets,
            folders: websiteResponse.data.assets.folders,
        })
    }),
}

export default model
