import { Box, Button, Stack, Typography } from '@mui/material'
import { CollectionControl, CollectionItemData } from 'drawerPanels/EditElementPanel/DialogElements/CollectionControl'
import { ElementDefinition, FieldDefinition, FieldType } from 'elementDefinitions'
import { useStoreActions, useStoreState } from 'store/hooks'
import { FieldLocator, LinkData, MediaData } from 'utils/types'
import { Headline } from './DialogElements/Headline'
import { InfoText } from './DialogElements/InfoText'
import { LinkControl } from './DialogElements/LinkControl'
import { MediaControl } from './DialogElements/MediaControl'
import { SelectControl } from './DialogElements/SelectControl'
import { TextareaControl } from './DialogElements/TextareaControl'
import { ToggleControl } from './DialogElements/ToggleControl'

export interface EditElementPanelProps {
    openPageLinkSelector: (fieldLocator: FieldLocator) => void
    openFileLinkSelector: (fieldLocator: FieldLocator) => void
    openMediaSelector: (fieldLocator: FieldLocator) => void
    deleteMediaSelection: (fieldLocator: FieldLocator) => void
}

const EditElementPanel = (props: EditElementPanelProps): JSX.Element => {
    const cancelEditElement = useStoreActions((actions) => actions.model.cancelEditElement)
    const changeElementValue = useStoreActions((actions) => actions.model.changeElementValue)
    const elementDefinitionList = useStoreState((state) => state.model.elementDefinitionList)
    const currentlyEditingElement = useStoreState((state) => state.model.currentlyEditingElement)
    const pageList = useStoreState((state) => state.model.pageList)
    const assets = useStoreState((state) => state.model.assets)

    const { openFileLinkSelector, openPageLinkSelector, openMediaSelector, deleteMediaSelection } = props
    const type: string = currentlyEditingElement ? currentlyEditingElement.type : ''

    const renderField = (field: FieldDefinition, index: number): JSX.Element => {
        if (field.type === FieldType.TEXT) {
            return (
                <TextareaControl
                    label={field.label}
                    key={field.label + index}
                    valueName={field.valueName}
                    lines={field.lines}
                    multiline={field.multiLine}
                    value={currentlyEditingElement?.data[field.valueName] as string}
                    onValueChange={(valueName: string, newValue: string) => {
                        changeElementValue({ newValue, valueName })
                    }}
                />
            )
        } else if (field.type === FieldType.LINK) {
            let val: LinkData = { url: '' }
            if (currentlyEditingElement?.data[field.valueName]) {
                val = currentlyEditingElement?.data[field.valueName] as LinkData
            }

            return (
                <LinkControl
                    assets={assets}
                    label={field.label}
                    key={field.label + index}
                    valueName={field.valueName}
                    value={val}
                    pageList={pageList}
                    disableExternal={field.disableExternal}
                    disableInternal={field.disableInternal}
                    disableFile={field.disableFile}
                    openFileLinkSelector={openFileLinkSelector}
                    openPageLinkSelector={openPageLinkSelector}
                    onValueChange={(valueName: string, newValue: LinkData) => {
                        changeElementValue({ newValue: newValue, valueName })
                    }}
                />
            )
        } else if (field.type === FieldType.MEDIA) {
            let val: MediaData = { __qbd_fileId: '', __qbd_altText: '' }
            if (currentlyEditingElement?.data[field.valueName]) {
                val = currentlyEditingElement?.data[field.valueName] as MediaData
            }

            return (
                <MediaControl
                    assets={assets}
                    label={field.label}
                    key={field.label + index}
                    valueName={field.valueName}
                    value={val}
                    openMediaSelector={openMediaSelector}
                    deleteMediaSelection={deleteMediaSelection}
                    onValueChange={(valueName: string, newValue: MediaData) => {
                        changeElementValue({ newValue, valueName })
                    }}
                />
            )
        } else if (field.type === FieldType.TOGGLE) {
            return (
                <ToggleControl
                    label={field.label}
                    key={field.label + index}
                    valueName={field.valueName}
                    value={currentlyEditingElement?.data[field.valueName] as string}
                    onValueChange={(valueName: string, newValue: string) => {
                        changeElementValue({ newValue, valueName })
                    }}
                />
            )
        } else if (field.type === FieldType.SELECT) {
            return (
                <SelectControl
                    label={field.label}
                    key={field.label + index}
                    valueName={field.valueName}
                    options={field.options}
                    radio={field.control === 'radio'}
                    value={currentlyEditingElement?.data[field.valueName] as string}
                    onValueChange={(valueName: string, newValue: string) => {
                        changeElementValue({ newValue, valueName })
                    }}
                />
            )
        } else if (field.type === FieldType.INFO_TEXT) {
            return <InfoText label={field.label} key={field.label + index} />
        } else if (field.type === FieldType.HEADLINE) {
            return <Headline text={field.text} key={field.text + index} />
        } else if (field.type === FieldType.COLLECTION) {
            const currenltyEditingCollectionData = currentlyEditingElement?.data[
                field.valueName
            ] as CollectionItemData[]
            return (
                <CollectionControl
                    key={field.title + index}
                    field={field}
                    collectionItems={currenltyEditingCollectionData}
                    openFileLinkSelector={openFileLinkSelector}
                    openPageLinkSelector={openPageLinkSelector}
                    openMediaSelector={openMediaSelector}
                    deleteMediaSelection={deleteMediaSelection}
                />
            )
        }

        return <></>
    }

    let elementDefinition: ElementDefinition | undefined
    if (type && type != '__qbd_block') {
        elementDefinition = elementDefinitionList.find((element) => element.identifier == type)
    } else if (type && type === '__qbd_block') {
        elementDefinition = {
            fields: [],
            name: 'Block',
            identifier: 'laksdjfl',
            initialData: {},
        }
    }

    return (
        <Box sx={{ width: '100%', padding: '1em', boxSizing: 'border-box' }}>
            {elementDefinition && (
                <>
                    <Typography variant="h6">{elementDefinition.name}</Typography>
                    {elementDefinition.fields.map(renderField)}
                </>
            )}
            <Stack spacing={2} direction="row" justifyContent="flex-end">
                <Button variant="contained" onClick={() => cancelEditElement()}>
                    OK
                </Button>
            </Stack>
        </Box>
    )
}

export default EditElementPanel
