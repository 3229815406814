import gql from 'graphql-tag'

export const SAVE_PAGE = gql`
    mutation savePage($input: SavePageInput!) {
        savePage(input: $input) {
            page {
                id
                parentPageIdentifier
                sortIndex
                deactivated
                createdAt
                updatedAt
                currentVersion {
                    ID
                    state
                    pageID
                    contents
                    createdAt
                    updatedAt
                    updatedBy {
                        id
                        name
                    }
                    pageSettings {
                        htmlTitle
                        urlTitle
                        metaDescription
                        metaKeywords
                        robotsOption
                        includeInSitemapXML
                        pageRank
                        changeFrequency
                        language
                        translationID
                    }
                }
                historyVersions {
                    ID
                    pageID
                    state
                    contents
                    createdAt
                    updatedAt
                    pageSettings {
                        htmlTitle
                        urlTitle
                        metaDescription
                        metaKeywords
                        robotsOption
                        includeInSitemapXML
                        pageRank
                        changeFrequency
                        language
                        translationID
                    }
                    updatedBy {
                        id
                        name
                    }
                }
            }
            linkedPages {
                id
                parentPageIdentifier
                sortIndex
                deactivated
                createdAt
                updatedAt
                currentVersion {
                    ID
                    state
                    pageID
                    contents
                    createdAt
                    updatedAt
                    updatedBy {
                        id
                        name
                    }
                    pageSettings {
                        htmlTitle
                        urlTitle
                        robotsOption
                        includeInSitemapXML
                        pageRank
                        changeFrequency
                        language
                        translationID
                    }
                }
                historyVersions {
                    ID
                    pageID
                    state
                    contents
                    createdAt
                    updatedAt
                    pageSettings {
                        htmlTitle
                        urlTitle
                        metaDescription
                        metaKeywords
                        robotsOption
                        includeInSitemapXML
                        pageRank
                        changeFrequency
                        language
                        translationID
                    }
                    updatedBy {
                        id
                        name
                    }
                }
            }
        }
    }
`
