import { useEffect, useRef } from 'react'
import { useStoreState } from 'store/hooks'
import {
    AssetSimplified,
    BlockData,
    Breakpoint,
    PageElement,
    PagePreviewCommandType,
    PagePreviewEvent,
} from 'utils/types'

export type PagePreviewProps = {
    activeBreakpoint: Breakpoint
    pageElements: PageElement[]
    onPreviewPageEvent?: (pe: PagePreviewEvent) => void
    blocks: BlockData[]
    assets: AssetSimplified[]
    disabled: boolean
    editHelpersVisible?: boolean
}

const PagePreview = (props: PagePreviewProps): JSX.Element => {
    const ref = useRef<HTMLIFrameElement>(null)
    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    // this useeffect listens for messages from inside the iframe and passes them to the handler
    useEffect(() => {
        if (selectedWebsite) {
            const handler = (message: any) => {
                // filter for messages from the iframe URL
                if (message.origin === selectedWebsite.previewURL) {
                    const data = JSON.parse(message.data)
                    console.log('received from iframe', data)
                    // if the iframe rendering tells us that it is finished rendering, send the current content automatically and set the
                    if (data.type === PagePreviewCommandType.PREVIEW_READY) {
                        sendToIframe(PagePreviewCommandType.UPDATE_CONTENT, {
                            pageContent: props.pageElements,
                            blocks: props.blocks,
                            assets: props.assets,
                        })
                        sendToIframe(PagePreviewCommandType.REFRESH_ELEMENT_OVERLAYS, {})
                        sendToIframe(PagePreviewCommandType.SET_OVERLAY, { enabled: props.disabled })
                        sendToIframe(PagePreviewCommandType.SET_EDIT_HELPERS, { visible: props.editHelpersVisible })
                    } else if (props.onPreviewPageEvent) {
                        props.onPreviewPageEvent(data)
                    }
                }
            }
            window.addEventListener('message', handler)
            return () => window.removeEventListener('message', handler)
        }
    }, [selectedWebsite])

    // send a message in our own little format to the parent window
    const sendToIframe = (messageType: PagePreviewCommandType, payload: any) => {
        console.log('sending message to iframe: ' + messageType + ' ' + JSON.stringify(payload))
        const message = JSON.stringify({
            type: messageType,
            payload: payload,
        })
        ref?.current?.contentWindow?.postMessage(message, '*')
    }

    const thisIsLame = JSON.stringify(props.pageElements) //TODO THIS IS NOT FINAL
    useEffect(() => {
        sendToIframe(PagePreviewCommandType.UPDATE_CONTENT, {
            pageContent: props.pageElements,
            blocks: props.blocks,
            assets: props.assets,
        })
        sendToIframe(PagePreviewCommandType.REFRESH_ELEMENT_OVERLAYS, {})
    }, [thisIsLame])

    useEffect(() => {
        sendToIframe(PagePreviewCommandType.SET_OVERLAY, { enabled: props.disabled }) // the page preview is disabled by enabling the overlay.
    }, [props.disabled])
    useEffect(() => {
        sendToIframe(PagePreviewCommandType.SET_EDIT_HELPERS, { visible: props.editHelpersVisible })
    }, [props.editHelpersVisible])

    return (
        <div
            style={{
                height: 'calc(100vh - 72px)',
                width: props.activeBreakpoint.editorCssWidth,
                overflow: 'hidden',
                transition: 'width 300ms',
            }}
        >
            {selectedWebsite && (
                <iframe
                    style={{
                        width: '100%',
                        height: '100%',
                        marginLeft: '50',
                        marginRight: '50',
                        border: '1px solid #888',
                    }}
                    name="pagePreview"
                    src={selectedWebsite.previewURL + '/_renderPreview'}
                    ref={ref}
                ></iframe>
            )}
        </div>
    )
}

export default PagePreview
