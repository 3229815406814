import { Add, Clear } from '@mui/icons-material'
import { Box, Button, Fab, IconButton, Stack, TextField, Typography } from '@mui/material'
import { DataType } from 'graphql/types'
import { UseDataItemPageReturn } from 'pages/DataItemPage/UseDataItemPage'
import { useState } from 'react'
import colors from 'utils/colors'
import { DataItemEditPanelItem } from './DataItemEditPanelItem'

export type DataItemEditPanelProps = Omit<UseDataItemPageReturn, 'selectedType'> & { selectedType: DataType }

const DataItemEditPanel = (props: DataItemEditPanelProps): JSX.Element | null => {
    const { items, attributes, onClickCreateItem, onClickPublishAll } = props

    const [searchTerm, setSearchTerm] = useState<string>('')

    return (
        <Box>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Stack direction="row" alignItems="center" spacing={2} mb={2}>
                    <TextField
                        id={'search'}
                        variant="filled"
                        size={'small'}
                        label={searchTerm === '' ? 'Search' : ''}
                        autoFocus
                        fullWidth
                        onChange={(e) => setSearchTerm(e.currentTarget.value)}
                        value={searchTerm}
                        sx={{
                            bgcolor: colors.white,
                            borderRadius: '4px',
                            '& .MuiInputBase-root': {
                                height: 36.5,
                            },
                            '& .MuiInputBase-input': {
                                paddingTop: '4px !important',
                            },
                            '& .MuiInputLabel-root': {
                                top: '-6px',
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    disabled={searchTerm === ''}
                                    sx={{ opacity: searchTerm === '' ? 0 : 1, marginRight: '-8px', marginLeft: '4px' }}
                                    onClick={() => {
                                        setSearchTerm('')
                                    }}
                                >
                                    <Clear />
                                </IconButton>
                            ),
                        }}
                    />
                </Stack>

                <Stack direction="row" alignItems="center" spacing={2} mb={2}>
                    <Button variant="contained" onClick={onClickPublishAll}>
                        Publish all
                    </Button>
                </Stack>
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                flex={1}
                gap={1}
                paddingX={3}
                paddingY={1}
                bgcolor={colors.white}
                alignItems={'center'}
            >
                <Typography fontWeight={700} fontSize={'24px'} width={'120px'}>
                    Published
                </Typography>
                {attributes
                    .filter((item) => item.common.visible)
                    .map((item) => (
                        <Typography key={item.id} fontWeight={700} fontSize={'24px'} flex={1}>
                            {item.common.name}
                        </Typography>
                    ))}
                <Box width={'40px'} />
            </Box>
            <Box maxHeight={'calc(100vh - 231px)'} sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
                {items
                    .filter(
                        (item) =>
                            searchTerm === '' ||
                            item.values.some((value) =>
                                String(value.value).toLowerCase().includes(searchTerm.toLowerCase()),
                            ),
                    )
                    .map((item) => (
                        <DataItemEditPanelItem key={item.id} {...props} item={item} />
                    ))}
            </Box>
            {items.length === 0 && (
                <Box
                    display="flex"
                    flexDirection="row"
                    flex={1}
                    gap={1}
                    alignItems={'center'}
                    paddingX={3}
                    paddingY={1}
                    sx={{
                        bgcolor: colors.whiteHalfOpacity,
                        fontSize: '18px',
                    }}
                    justifyContent="center"
                >
                    There are no rows at the moment. You can add a new row by clicking the + button.
                </Box>
            )}
            <Fab
                color={'primary'}
                aria-label="add"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={onClickCreateItem}
            >
                <Add />
            </Fab>
        </Box>
    )
}

export default DataItemEditPanel
