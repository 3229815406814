import { Button, Stack, TextField } from '@mui/material'
import { DataItemEditPanelProps } from 'drawerPanels/DataItemEditPanel/DataItemEditPanel'
import { UseDataItemPageReturn } from 'pages/DataItemPage/UseDataItemPage'
import { AttributeUnion, AttributeValueUnion, DataItemUnion } from 'utils/dataType/types'
import { AttributeValueEdit } from './AttributeValueEdit'

export type DataItemDetailsPanelProps = Omit<DataItemEditPanelProps, 'selectedItem'> & { selectedItem: DataItemUnion }
export type AttributeValueEditProps = UseDataItemPageReturn & {
    attribute: AttributeUnion | undefined
    attributeValue: AttributeValueUnion
    setAttributeValue: (attributeValue: AttributeValueUnion) => void
    hideDivider?: boolean
}

const DataItemDetailsPanel = (props: DataItemDetailsPanelProps): JSX.Element | null => {
    const { onClickSaveItem, onClickCancelItem, selectedItem, setSelectedItem, attributes } = props

    const setAttributeValue = (value: AttributeValueUnion) =>
        setSelectedItem({
            id: selectedItem.id,
            published: selectedItem.published,
            created: selectedItem.created,
            updated: selectedItem.updated,
            values: selectedItem.values.map((item) => (item.attributeID === value.attributeID ? value : item)),
        })

    return (
        <Stack direction="column" p={2} spacing={1} mt={0.25} position={'relative'}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mb={2}>
                <Button variant="contained" color={'secondary'} onClick={onClickCancelItem}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={onClickSaveItem}>
                    Save
                </Button>
            </Stack>
            <TextField label={'ID'} value={selectedItem.id} disabled />
            {selectedItem.values.map((attributeValue) => (
                <AttributeValueEdit
                    key={attributeValue.attributeID}
                    {...props}
                    attribute={attributes.find((attribute) => attribute.id === attributeValue.attributeID)}
                    attributeValue={attributeValue}
                    setAttributeValue={setAttributeValue}
                />
            ))}
        </Stack>
    )
}

export default DataItemDetailsPanel
