import { Checkbox, Divider, FormControlLabel } from '@mui/material'
import { AttributeBoolean } from 'graphql/types'
import { AttributeEditProps } from './AttributeCommonEdit'

export const AttributeBooleanEdit = (props: AttributeEditProps) => {
    const { selectedAttribute, setSelectedAttribute } = props

    if (selectedAttribute.__typename !== 'AttributeBoolean') return null

    const { defaultValue } = selectedAttribute

    const override = (values: Partial<AttributeBoolean>) =>
        setSelectedAttribute({
            ...selectedAttribute,
            ...values,
        })

    return (
        <>
            <Divider />

            <FormControlLabel
                control={
                    <Checkbox
                        defaultChecked={defaultValue ?? undefined}
                        onChange={(event) => override({ defaultValue: event.target.checked })}
                    />
                }
                label="Checked by default"
            />
        </>
    )
}
