import { createTheme, responsiveFontSizes, Theme } from '@mui/material/styles'
//import colors from './colors'
//import fontStyles from './fontStyles'

// const breakpoints = createBreakpoints({})
// export const smallMobile = 385
// export const smallestMobile = 360

const theme: Theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: 'black',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    wordBreak: 'break-all',
                },
            },
        },
    },
    //MuiTypography: {
    //styleOverrides: {
    //root: {
    //'&.ExampleText': {
    //textAlign: 'center',
    //},
    //},
    //},
    //},
    //MuiBackdrop: {
    //styleOverrides: {
    //root: {
    //'&.LoadingSpinner': {
    //zIndex: 1400,
    //color: '#fff',
    //},
    //},
    //},
    //},
    //MuiCircularProgress: {
    //styleOverrides: {
    //root: {
    //'&.LoadingSpinner': {
    //marginLeft: 'auto',
    //marginRight: 'auto',
    //},
    //},
    //},
    //},
    //MuiList: {
    //styleOverrides: {
    //root: {
    //'&.ErrorBox': {
    //padding: 20,
    //marginTop: 20,
    //width: '100%',
    //borderRadius: 4,
    //backgroundColor: colors.lightWarning,
    //},
    //},
    //},
    //},
    //MuiListItem: {
    //styleOverrides: {
    //root: {
    //'&.ErrorBoxListItem': {
    //color: colors.warning,
    //},
    //},
    //},
    //},
    //MuiListSubheader: {
    //styleOverrides: {
    //root: {
    //'&.ErrorBox': {
    //color: colors.warning,
    //fontSize: '1.375rem',
    //},
    //},
    //},
    //},
    //MuiListItemIcon: {
    //styleOverrides: {
    //root: {
    //'&.ErrorBox': {
    //height: '7px',
    //width: '7px',
    //minWidth: 28,
    //},
    //},
    //},
    //},
    //MuiSvgIcon: {
    //styleOverrides: {
    //root: {
    //'&.ErrorBox': {
    //height: '7px',
    //width: '7px',
    //color: colors.warning,
    //},
    //},
    //},
    //},
    //},
    palette: {
        primary: {
            main: '#5eafc7',
        },
        secondary: {
            main: '#cccccc',
        },
    },
})

export const ModifiedTheme = responsiveFontSizes(theme)
