import CMSHeader from 'components/CMSHeader/CMSHeader'
import DrawerGrid from 'components/DrawerGrid'
import { DrawerGridProps, DrawerWidth } from 'components/DrawerGrid/DrawerGrid'
import AttributeDetailsPanel from 'drawerPanels/AttributeDetailsPanel/AttributeDetailsPanel'
import DataTypeEditPanel from 'drawerPanels/DataTypeEditPanel/DataTypeEditPanel'
import DataTypePanel from 'drawerPanels/DataTypePanel/DataTypePanel'
import { DataTypeCategory } from 'graphql/types'
import { useStoreActions, useStoreState } from 'store/hooks'
import { useDataTypesTab } from './useDataTypeTab'

export interface DataTypeTabProps {
    category: DataTypeCategory
}

export const DataTypeTab = (props: DataTypeTabProps): JSX.Element => {
    const drawerGridProps: DrawerGridProps = {
        primaryWidth: DrawerWidth.SMALL,
        secondaryWidth: DrawerWidth.LARGE,
        collapsible: true,
        tabNavigation: true,
    }

    const websiteList = useStoreState((state) => state.model.websiteList)
    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    const setSelectedWebsite = useStoreActions((actions) => actions.model.setSelectedWebsite)

    const hookReturn = useDataTypesTab(props)
    const { unpublishedItems, selectedType, selectedAttribute } = hookReturn

    drawerGridProps.primary = <DataTypePanel {...hookReturn} />

    if (selectedType && selectedAttribute) {
        drawerGridProps.secondary = (
            <AttributeDetailsPanel {...hookReturn} selectedItem={selectedType} selectedAttribute={selectedAttribute} />
        )
    }

    if (selectedType) drawerGridProps.content = <DataTypeEditPanel {...hookReturn} selectedItem={selectedType} />

    return (
        <>
            <CMSHeader
                unpublishedItems={unpublishedItems}
                selectedWebsite={selectedWebsite?.id ?? ''}
                websiteList={websiteList}
                onSelectWebsite={setSelectedWebsite}
            />
            <DrawerGrid {...drawerGridProps} />
        </>
    )
}
