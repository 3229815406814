import { Asset, Page } from 'graphql/types'

export const resolvePagelURL = (pageID: string, allPages: Page[], omitLastPage?: boolean): string => {
    let rootPath = ''
    const page = allPages.find((p: Page) => p.id === pageID)
    if (page === undefined) return ''
    if (page.parentPageIdentifier && page.parentPageIdentifier.trim().length > 0) {
        rootPath = resolvePagelURL(page.parentPageIdentifier, allPages)
    }
    return rootPath + (!omitLastPage ? '/' + page.currentVersion.pageSettings.urlTitle : '/')
}

export type FileInfo = {
    url: string
    thumbnailUrl: string
    name: string
    size: number
    type: string
}

export const resolveFileData = (fileID: string, assets: Asset[]): FileInfo | null => {
    for (const asset of assets) {
        if (asset.id === fileID) {
            return {
                url: asset.url ?? '',
                thumbnailUrl: asset.thumbnailUrl ?? '',
                name: asset.name,
                size: asset.sizeBytes,
                type: asset.type,
            }
        }
    }
    return null
}
