import DeleteIcon from '@mui/icons-material/Delete'
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { Frequency, PageRank, PageSettingInput, PageSettings, RobotsOption } from 'graphql/types'
import { Controller, useForm } from 'react-hook-form'
import { checkVersionDaemon } from 'utils/checkVersionDaemon'
import { LanguageVersion, LinkedLanguagePages } from 'utils/types'

export interface PageSettingsEditPanelProps {
    onCancel: () => void
    onSubmit: (pageSettings: PageSettingInput) => void
    openPageLinkSelector: () => void
    removeCurrentLanguageVersion: (id: string) => void
    domain: string
    pagePath: string
    pageSettings: PageSettings
    languageVersion?: LanguageVersion
    saveIsDisabled: boolean
}

interface PageSettingsEditInputs {
    PageTitle: string
    PageURL: string
    MetaDescription: string
    MetaKeywords: string
    ChangeFrequency: Frequency
    IncludeInSitemapXML: boolean
    PageRank: PageRank
    RobotsOption: RobotsOption
    Language: string
}

const PageSettingsEditPanel = (props: PageSettingsEditPanelProps): JSX.Element => {
    const {
        onCancel,
        onSubmit,
        openPageLinkSelector,
        removeCurrentLanguageVersion,
        pageSettings,
        domain,
        pagePath,
        languageVersion,
        saveIsDisabled,
    } = props

    //initalise deamon to check version
    checkVersionDaemon()

    const { handleSubmit, control } = useForm<PageSettingsEditInputs>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {
            PageTitle: pageSettings.htmlTitle,
            PageURL: pageSettings.urlTitle,
            MetaDescription: pageSettings.metaDescription,
            MetaKeywords: pageSettings.metaKeywords,
            IncludeInSitemapXML: pageSettings.includeInSitemapXML,
            ChangeFrequency: pageSettings.changeFrequency,
            PageRank: pageSettings.pageRank,
            RobotsOption: pageSettings.robotsOption,
            Language: pageSettings.language,
        },
    })

    const handleOnSave = () => {
        handleSubmit((data: PageSettingsEditInputs) => {
            const pageSettings: PageSettingInput = {
                changeFrequency: data.ChangeFrequency,
                htmlTitle: data.PageTitle,
                includeInSitemapXML: data.IncludeInSitemapXML,
                metaDescription: data.MetaDescription,
                metaKeywords: data.MetaKeywords,
                pageRank: data.PageRank,
                robotsOption: data.RobotsOption,
                urlTitle: data.PageURL,
                language: data.Language,
            }
            //check if language version exist
            if (languageVersion && languageVersion.linkedLanguagePages.length > 0) {
                //validate if in the language version exist pages with the same language
                //TODO: create custom function
                const usedLangusages: string[] = []
                for (const lvp of languageVersion.linkedLanguagePages) {
                    if (lvp.language === data.Language || usedLangusages.includes(data.Language)) {
                        alert('Please check your language versions. No language may appear twice.')
                        return
                    } else {
                        usedLangusages.push(lvp.language)
                    }
                }
                //set the current language version
                pageSettings.translationID = languageVersion.translationID
                pageSettings.linkedLanguagePagesIds = languageVersion.linkedLanguagePages.map((lvp) => {
                    return lvp.pageId
                })
            }
            onSubmit(pageSettings)
        })()
    }

    const renderPageVersionEntry = (version: LinkedLanguagePages): JSX.Element | undefined => {
        if (version.pageId !== '') {
            return (
                <ListItem
                    key={version.pageId}
                    secondaryAction={
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                                removeCurrentLanguageVersion(version.pageId)
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    }
                >
                    <ListItemText primary={version.url} secondary={version.language} />
                </ListItem>
            )
        }
    }
    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">Page Settings</Typography>
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="PageTitle"
                    control={control}
                    rules={{
                        required: 'Please enter a page title',
                        maxLength: { value: 150, message: 'The PageTitle may only be 150 characters long' },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label="Page Title"
                            fullWidth={true}
                            {...field}
                            inputProps={{ maxLength: 150 }}
                            helperText={fieldState.error?.message ?? ''}
                            variant={'filled'}
                        />
                    )}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="PageURL"
                    control={control}
                    rules={{
                        required: 'Please enter a page url',
                        maxLength: { value: 150, message: 'The PageURL may only be 150 characters long' },
                    }}
                    render={({ field, fieldState }) => {
                        return (
                            <>
                                <TextField
                                    label="Page URL"
                                    fullWidth={true}
                                    {...field}
                                    inputProps={{ maxLength: 150 }}
                                    helperText={fieldState.error?.message ?? ''}
                                    variant={'filled'}
                                />

                                <Typography>{domain + pagePath + field.value}</Typography>
                            </>
                        )
                    }}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="MetaDescription"
                    control={control}
                    rules={{
                        maxLength: { value: 150, message: 'The MetaDescription may only be 150 characters long' },
                    }}
                    render={({ field }) => {
                        return (
                            <TextField
                                multiline
                                minRows={4}
                                maxRows={4}
                                {...field}
                                fullWidth={true}
                                inputProps={{ maxLength: 150 }}
                                label="Meta Description"
                                variant={'filled'}
                            />
                        )
                    }}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="MetaKeywords"
                    control={control}
                    rules={{
                        maxLength: {
                            value: 500,
                            message: 'The meta keywords together must not be longer than 500 characters',
                        },
                    }}
                    render={({ field }) => {
                        return (
                            <TextField
                                multiline
                                minRows={4}
                                maxRows={4}
                                {...field}
                                fullWidth={true}
                                inputProps={{ maxLength: 500 }}
                                label="Meta Keywords"
                                variant={'filled'}
                            />
                        )
                    }}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="RobotsOption"
                    control={control}
                    render={({ field }) => {
                        return (
                            <FormControl fullWidth variant={'filled'}>
                                <InputLabel id="page-settings-robots-label">Information for search engines:</InputLabel>
                                <Select
                                    labelId="page-settings-robots-label"
                                    id="page-settings-robots"
                                    label="Information for search engines:"
                                    {...field}
                                >
                                    <MenuItem value={RobotsOption.BOTH}>Index page and follow links</MenuItem>
                                    <MenuItem value={RobotsOption.INDEX}>Index page, but do not follow links</MenuItem>
                                    <MenuItem value={RobotsOption.FOLLOW}>Do not index page, but follow links</MenuItem>
                                    <MenuItem value={RobotsOption.NONE}>
                                        Do not index the page and do not follow links
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        )
                    }}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="IncludeInSitemapXML"
                    control={control}
                    render={({ field }) => {
                        return <FormControlLabel control={<Checkbox {...field} />} label="omit in the sitemap.xml" />
                    }}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="ChangeFrequency"
                    control={control}
                    render={({ field }) => {
                        return (
                            <FormControl fullWidth variant={'filled'}>
                                <InputLabel id="page-settings-change-frequency-label">Frequency of change:</InputLabel>
                                <Select
                                    labelId="page-settings-change-frequency-label"
                                    id="page-settings-change-frequency"
                                    label="Frequency of change:"
                                    {...field}
                                >
                                    <MenuItem value={Frequency.ALWAYS}>always</MenuItem>
                                    <MenuItem value={Frequency.HOURLY}>hourly</MenuItem>
                                    <MenuItem value={Frequency.DAILY}>daily</MenuItem>
                                    <MenuItem value={Frequency.WEEKLY}>weekly</MenuItem>
                                    <MenuItem value={Frequency.MONTHLY}>monthly</MenuItem>
                                    <MenuItem value={Frequency.YEARLY}>yearly</MenuItem>
                                    <MenuItem value={Frequency.NEVER}>never</MenuItem>
                                </Select>
                            </FormControl>
                        )
                    }}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="PageRank"
                    control={control}
                    render={({ field }) => {
                        return (
                            <FormControl fullWidth variant={'filled'}>
                                <InputLabel id="page-settings-rank-label">Importance to other pages:</InputLabel>
                                <Select
                                    labelId="page-settings-rank-label"
                                    id="page-settings-rank"
                                    label="Importance to other pages:"
                                    {...field}
                                >
                                    <MenuItem value={PageRank.ONE}>0.1</MenuItem>
                                    <MenuItem value={PageRank.TWO}>0.2</MenuItem>
                                    <MenuItem value={PageRank.THREE}>0.3</MenuItem>
                                    <MenuItem value={PageRank.FOUR}>0.4</MenuItem>
                                    <MenuItem value={PageRank.FIVE}>0.5</MenuItem>
                                    <MenuItem value={PageRank.SIX}>0.6</MenuItem>
                                    <MenuItem value={PageRank.SEVEN}>0.7</MenuItem>
                                    <MenuItem value={PageRank.EIGHT}>0.8</MenuItem>
                                    <MenuItem value={PageRank.NINE}>0.9</MenuItem>
                                    <MenuItem value={PageRank.TEN}>1.0</MenuItem>
                                </Select>
                            </FormControl>
                        )
                    }}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="Language"
                    control={control}
                    render={({ field }) => {
                        return (
                            <FormControl fullWidth variant={'filled'}>
                                <InputLabel id="page-settings-language-label">Languages:</InputLabel>
                                <Select
                                    labelId="page-settings-language-label"
                                    id="page-settings-language "
                                    label="Languages:"
                                    {...field}
                                >
                                    <MenuItem value="DE">German</MenuItem>
                                    <MenuItem value="EN">English</MenuItem>
                                    <MenuItem value="ES">Spanish</MenuItem>
                                    <MenuItem value="FR">French</MenuItem>
                                    <MenuItem value="IT">Italian</MenuItem>
                                    <MenuItem value="FI">Finnish</MenuItem>
                                    <MenuItem value="NL">Dutch</MenuItem>
                                    <MenuItem value="PT">Portuguese</MenuItem>
                                    <MenuItem value="NO">Norwegian</MenuItem>
                                    <MenuItem value="RU">Russian</MenuItem>
                                    <MenuItem value="SV">Swedish</MenuItem>
                                    <MenuItem value="SL">Slovenian</MenuItem>
                                    <MenuItem value="HU">Hungarian</MenuItem>
                                </Select>
                            </FormControl>
                        )
                    }}
                />
            </Grid>
            <Grid container item direction={'column'} justifyContent={'space-between'} mb={2}>
                <Typography variant="h6">Language versions</Typography>
                {languageVersion && (
                    <List>
                        {languageVersion.linkedLanguagePages.map(renderPageVersionEntry)}
                        {languageVersion.linkedLanguagePages.length > 1 && <Divider />}
                    </List>
                )}
                <Button variant="contained" color={'secondary'} onClick={openPageLinkSelector}>
                    Select page
                </Button>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" color={'secondary'} onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleOnSave} disabled={saveIsDisabled}>
                    Save
                </Button>
            </Stack>
        </Box>
    )
}

export default PageSettingsEditPanel
