import { StoreProvider } from 'easy-peasy'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import './index.css'
import store from './store'

const rootElement = document.getElementById('root')
//NOTE: root is always there we know that otherwise the compile will pop a warning
//eslint-disable-next-line
const root = createRoot(rootElement!)

//TODO: update easy-peasy when the underlying bug is resolved this is a workaround
const StoreProviderOverride = StoreProvider as any

//TODO: add StrictMode when easy-peasy is updated to support react18 types

//NOTE: StrictMode causes easy-peasy not to render correct since it is async: github issue:
// https://github.com/ctrlplusb/easy-peasy/issues/740
root.render(
    <StoreProviderOverride store={store}>
        <App />
    </StoreProviderOverride>,
)
