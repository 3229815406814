import gql from 'graphql-tag'
import { fragmentDataItem, fragmentDataType } from 'graphql/fragments'

export const DATA_TYPE_GET = gql`
    ${fragmentDataType}
    query dataTypeGet($category: DataTypeCategory!, $website: ID!) {
        dataTypeGet(category: $category, website: $website) {
            ...fragmentDataType
        }
    }
`

export const DATA_TYPE_GET_WITH_ITEMS = gql`
    ${fragmentDataType}
    ${fragmentDataItem}
    query dataModuleGetWithItems($category: DataTypeCategory!, $website: ID!) {
        dataTypeGetWithItems(category: $category, website: $website) {
            dataType {
                ...fragmentDataType
            }
            dataItems {
                ...fragmentDataItem
            }
        }
    }
`
