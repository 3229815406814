import gql from 'graphql-tag'

export const DATA_TYPE_CREATE = gql`
    mutation dataTypeCreate($input: DataTypeInput!, $website: ID!) {
        dataTypeCreate(input: $input, website: $website)
    }
`

export const DATA_TYPE_UPDATE = gql`
    mutation dataTypeUpdate($input: DataTypeInput!) {
        dataTypeUpdate(input: $input)
    }
`

export const DATA_TYPE_DELETE = gql`
    mutation dataTypeDelete($id: ID!) {
        dataTypeDelete(id: $id)
    }
`

export const DATA_TYPE_REMOVE_ATTRIBUTE = gql`
    mutation dataTypeRemoveAttribute($id: ID!) {
        dataTypeRemoveAttribute(id: $id)
    }
`

export const DATA_TYPE_REORDER_ATTRIBUTES = gql`
    mutation dataTypeReorderAttributes($order: [ID!]!) {
        dataTypeReorderAttributes(order: $order)
    }
`
