import { Button, Stack } from '@mui/material'
import { DataType } from 'graphql/types'
import { UseDataTypeTabsReturn } from 'pages/SettingsPage/DataTypeTab/useDataTypeTab'
import { AttributeUnion } from 'utils/dataType/types'
import { AttributeAssetEdit } from './AttributeAssetEdit'
import { AttributeBooleanEdit } from './AttributeBooleanEdit'
import { AttributeCollectionEdit } from './AttributeCollectionEdit'
import { AttributeCommonEdit } from './AttributeCommonEdit'
import { AttributeLinkEdit } from './AttributeLinkEdit'
import { AttributeNumberEdit } from './AttributeNumberEdit'
import { AttributeSelectionEdit } from './AttributeSelectionEdit'
import { AttributeTextEdit } from './AttributeTextEdit'

export type AttributeDetailsPanelProps = Omit<UseDataTypeTabsReturn, 'selectedItem' | 'selectedAttribute'> & {
    selectedItem: DataType
    selectedAttribute: AttributeUnion
}

const AttributeDetailsPanel = (props: AttributeDetailsPanelProps): JSX.Element | null => {
    const { onClickCancelAttribute, onClickSaveAttribute } = props

    return (
        <Stack direction="column" p={2} spacing={1} mt={0.25} position={'relative'} gap={1}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} marginBottom={1}>
                <Button variant="contained" color={'secondary'} onClick={onClickCancelAttribute}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={onClickSaveAttribute}>
                    Save
                </Button>
            </Stack>

            {/* Render specific properties, actual check is done in components */}
            <AttributeCommonEdit {...props} />
            <AttributeTextEdit {...props} />
            <AttributeNumberEdit {...props} />
            <AttributeBooleanEdit {...props} />
            <AttributeLinkEdit {...props} />
            <AttributeAssetEdit {...props} />
            <AttributeCollectionEdit {...props} />
            <AttributeSelectionEdit {...props} />
        </Stack>
    )
}

export default AttributeDetailsPanel
