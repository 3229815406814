import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { AttributeCommon, DataTypeAttributeType } from 'graphql/types'
import { getTypenameFromAttributeType } from 'utils/dataType/functions'
import { AttributeDetailsPanelProps } from './AttributeDetailsPanel'

export type AttributeEditProps = Pick<AttributeDetailsPanelProps, 'selectedAttribute' | 'setSelectedAttribute'>

export const AttributeCommonEdit = (props: AttributeEditProps) => {
    const { selectedAttribute, setSelectedAttribute } = props

    const {
        common: { identifier, name, description },
    } = selectedAttribute

    const overrideCommon = (values: Partial<AttributeCommon>) =>
        setSelectedAttribute({
            ...selectedAttribute,
            common: { ...selectedAttribute.common, ...values },
        })

    return (
        <>
            <FormControl fullWidth variant={'filled'} disabled>
                <InputLabel>Type</InputLabel>
                <Select label="Type" value={selectedAttribute.__typename}>
                    {Object.values(DataTypeAttributeType)
                        .map(getTypenameFromAttributeType)
                        .map((item) => (
                            <MenuItem key={item} value={item}>
                                {item.replace('Attribute', '')}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <TextField
                label={'Identifier'}
                value={identifier}
                onChange={(event) => overrideCommon({ identifier: event.target.value })}
            />
            <TextField label={'Name'} value={name} onChange={(event) => overrideCommon({ name: event.target.value })} />
            <TextField
                label={'Description'}
                value={description}
                onChange={(event) => overrideCommon({ description: event.target.value })}
            />
        </>
    )
}
