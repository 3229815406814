import { Autocomplete, Checkbox, FormControlLabel, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { AttributeLinkValue } from 'graphql/types'
import { useEffect, useState } from 'react'
import { LinkOption, getLinkOptionFromValue, shouldLinkShowOption } from 'utils/dataType/types'
import { AttributeValueEditProps } from './DataItemDetailsPanel'

export const AttributeLinkValueEdit = (props: AttributeValueEditProps) => {
    const { attribute, attributeValue, setAttributeValue, pages, assets } = props

    if (attributeValue.__typename !== 'AttributeLinkValue') return null
    if (!attribute || attribute.__typename !== 'AttributeLink') return null

    const { value, newWindow } = attributeValue

    const override = (values: Partial<AttributeLinkValue>) =>
        setAttributeValue({
            ...attributeValue,
            ...values,
        })

    const firstValidOption = Object.keys(LinkOption)
        .filter((item) => !Number.isNaN(item))
        .filter((item) => shouldLinkShowOption(attribute, item as LinkOption))[0] as LinkOption

    const [tab, setTab] = useState<LinkOption>(firstValidOption)

    // set initially selected tab if we already have a value
    useEffect(() => setTab(getLinkOptionFromValue(value)), [value])

    return (
        <>
            <ToggleButtonGroup
                color="primary"
                value={tab}
                exclusive
                size="small"
                fullWidth
                onChange={(_, value: LinkOption) => {
                    // change tab and reset value
                    setTab(value)
                    override({ value: '' })
                }}
            >
                {Object.keys(LinkOption)
                    .filter((item) => !Number.isNaN(item))
                    .map((item) => (
                        <ToggleButton
                            key={item}
                            value={item}
                            disabled={!shouldLinkShowOption(attribute, item as LinkOption)}
                        >
                            {item}
                        </ToggleButton>
                    ))}
            </ToggleButtonGroup>
            {/* TODO: This is throwing uncontrolled/invalid option errors but works fine */}
            {tab === LinkOption.INTERNAL && (
                <Autocomplete
                    options={pages}
                    getOptionLabel={(option) => option.currentVersion.pageSettings.htmlTitle}
                    defaultValue={pages.find((item) => item.id === value)}
                    onChange={(_, value) => override({ value: value?.id ?? '' })}
                    renderInput={(params) => <TextField {...params} label={attribute.common.name} />}
                />
            )}
            {tab === LinkOption.EXTERNAL && (
                <TextField
                    label={attribute.common.name}
                    value={value}
                    onChange={(event) => override({ value: event.target.value })}
                />
            )}
            {tab === LinkOption.FILE && (
                <Autocomplete
                    options={assets}
                    getOptionLabel={(option) => option.label}
                    defaultValue={assets.find((item) => item.id === value)}
                    onChange={(_, value) => override({ value: value?.id ?? '' })}
                    renderInput={(params) => <TextField {...params} label={attribute.common.name} />}
                />
            )}
            <FormControlLabel
                control={
                    <Checkbox checked={newWindow} onChange={(event) => override({ newWindow: event.target.checked })} />
                }
                label={'Open in new window?'}
            />
        </>
    )
}
