import { Delete, DragIndicator } from '@mui/icons-material'
import {
    Box,
    Button,
    Card,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import { AttributeSelection, AttributeSelectionOption } from 'graphql/types'
import { DragDropContext, Draggable, DraggableProvided, Droppable, OnDragEndResponder } from 'react-beautiful-dnd'
import { v4 } from 'uuid'
import { AttributeEditProps } from './AttributeCommonEdit'

export const AttributeSelectionEdit = (props: AttributeEditProps) => {
    const { selectedAttribute, setSelectedAttribute } = props

    if (selectedAttribute.__typename !== 'AttributeSelection') return null

    const { options, defaultValue } = selectedAttribute

    const override = (values: Partial<AttributeSelection>) =>
        setSelectedAttribute({
            ...selectedAttribute,
            ...values,
        })

    const onDragEnd: OnDragEndResponder = (result) => {
        const { destination, source } = result
        if (!destination) return
        if (destination.droppableId === source.droppableId && destination.index === source.index) return
        if (destination.droppableId !== source.droppableId) return

        const list = Array.from(options)
        const [removed] = list.splice(source.index, 1)
        list.splice(destination.index, 0, removed)

        override({ options: list })
    }

    const onClickAddOption = () => {
        override({ options: [...options, { id: v4(), name: '', description: '' }] })
    }

    const onClickDeleteOption = (option: AttributeSelectionOption) => {
        override({ options: options.filter((item) => item.id !== option.id) })
    }

    const onChangeItem = (item: AttributeSelectionOption) =>
        override({ options: options.map((old) => (old.id === item.id ? item : old)) })

    return (
        <>
            <Divider />

            <FormControl fullWidth variant={'filled'}>
                <InputLabel>Default selection</InputLabel>
                <Select
                    label="Default selection"
                    value={defaultValue ?? ''}
                    onChange={(event) => override({ defaultValue: event.target.value })}
                >
                    {options.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <DragDropContext onDragEnd={onDragEnd}>
                <Box sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
                    <Droppable droppableId={'options'}>
                        {(provided) => (
                            <span {...provided.droppableProps} ref={provided.innerRef}>
                                {options.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided: DraggableProvided) => (
                                            <Card
                                                key={item.id}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                component={Box}
                                                display={'flex'}
                                                flexDirection={'row'}
                                                padding={'8px'}
                                                marginBottom={'8px'}
                                                alignItems={'center'}
                                                position={'relative'}
                                                sx={{
                                                    ':hover': {
                                                        '> button': {
                                                            display: 'block',
                                                        },
                                                    },
                                                    '> button': {
                                                        display: 'none',
                                                    },
                                                }}
                                            >
                                                <DragIndicator fontSize="large" />
                                                <Box
                                                    display="flex"
                                                    flexDirection={'column'}
                                                    flex={1}
                                                    gap={'8px'}
                                                    marginLeft={'8px'}
                                                >
                                                    <TextField
                                                        label="Name"
                                                        value={item.name}
                                                        variant={'standard'}
                                                        onChange={(event) =>
                                                            onChangeItem({ ...item, name: event.target.value })
                                                        }
                                                    />
                                                    <TextField
                                                        label="Description"
                                                        value={item.description}
                                                        variant={'standard'}
                                                        onChange={(event) =>
                                                            onChangeItem({ ...item, description: event.target.value })
                                                        }
                                                    />
                                                </Box>
                                                <Tooltip title={'Remove item from list'}>
                                                    <IconButton
                                                        color={'error'}
                                                        onClick={() => onClickDeleteOption(item)}
                                                        sx={{ position: 'absolute', top: 8, right: 8 }}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </Card>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </span>
                        )}
                    </Droppable>

                    {options.length === 0 && (
                        <Typography marginBottom={'8px'}>
                            No options yet, click on the button below to add some
                        </Typography>
                    )}

                    <Button variant={'contained'} fullWidth onClick={onClickAddOption}>
                        Add item
                    </Button>
                </Box>
            </DragDropContext>
        </>
    )
}
