import { AttributeEditProps } from './AttributeCommonEdit'

export const AttributeAssetEdit = (props: AttributeEditProps) => {
    const { selectedAttribute } = props

    if (selectedAttribute.__typename !== 'AttributeAsset') return null

    const {} = selectedAttribute

    // const override = (values: Partial<AttributeAsset>) =>
    //     setSelectedAttribute({
    //         ...selectedAttribute,
    //         ...values,
    //     })

    return null
}
