export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string }
    String: { input: string; output: string }
    Boolean: { input: boolean; output: boolean }
    Int: { input: number; output: number }
    Float: { input: number; output: number }
}

export type Asset = {
    __typename?: 'Asset'
    createdAt: Scalars['Int']['output']
    createdBy: Scalars['String']['output']
    folder?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    sizeBytes: Scalars['Int']['output']
    tags?: Maybe<Array<Scalars['String']['output']>>
    thumbnailUrl?: Maybe<Scalars['String']['output']>
    type: AssetType
    updatedAt: Scalars['Int']['output']
    updatedBy: Scalars['String']['output']
    url: Scalars['String']['output']
    websiteID: Scalars['String']['output']
}

export type AssetFolder = {
    __typename?: 'AssetFolder'
    createdAt: Scalars['Int']['output']
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    parentFolder?: Maybe<Scalars['String']['output']>
    tags?: Maybe<Array<Scalars['String']['output']>>
    websiteID: Scalars['ID']['output']
}

export type AssetResponse = {
    __typename?: 'AssetResponse'
    assets: Array<Asset>
    folders: Array<AssetFolder>
}

export enum AssetType {
    IMAGE = 'IMAGE',
    OTHER = 'OTHER',
    PDF = 'PDF',
}

export type AssetUpdateInput = {
    AssetID: Scalars['ID']['input']
    folderID?: InputMaybe<Scalars['ID']['input']>
    name: Scalars['String']['input']
    tags?: InputMaybe<Array<Scalars['String']['input']>>
}

export type AssetUploadInput = {
    file: Scalars['String']['input']
    folderID: Scalars['String']['input']
    name: Scalars['String']['input']
    resizeImages: Scalars['Boolean']['input']
    type: AssetType
    websiteID: Scalars['String']['input']
}

export type AttributeAsset = {
    __typename?: 'AttributeAsset'
    common: AttributeCommon
    id: Scalars['ID']['output']
}

export type AttributeAssetInput = {
    common: AttributeCommonInput
    id: Scalars['ID']['input']
}

export type AttributeAssetValue = {
    __typename?: 'AttributeAssetValue'
    alt?: Maybe<Scalars['String']['output']>
    attributeID: Scalars['ID']['output']
    value: Scalars['ID']['output']
}

export type AttributeAssetValueInput = {
    alt?: InputMaybe<Scalars['String']['input']>
    attributeID: Scalars['ID']['input']
    value: Scalars['ID']['input']
}

export type AttributeBoolean = {
    __typename?: 'AttributeBoolean'
    common: AttributeCommon
    defaultValue?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
}

export type AttributeBooleanInput = {
    common: AttributeCommonInput
    defaultValue?: InputMaybe<Scalars['Boolean']['input']>
    id: Scalars['ID']['input']
}

export type AttributeBooleanValue = {
    __typename?: 'AttributeBooleanValue'
    attributeID: Scalars['ID']['output']
    value: Scalars['Boolean']['output']
}

export type AttributeBooleanValueInput = {
    attributeID: Scalars['ID']['input']
    value: Scalars['Boolean']['input']
}

export type AttributeCollection = {
    __typename?: 'AttributeCollection'
    common: AttributeCommon
    dataType: Scalars['ID']['output']
    id: Scalars['ID']['output']
}

export type AttributeCollectionInput = {
    common: AttributeCommonInput
    dataType: Scalars['ID']['input']
    id: Scalars['ID']['input']
}

export type AttributeCollectionValue = {
    __typename?: 'AttributeCollectionValue'
    attributeID: Scalars['ID']['output']
    value: Array<AttributeCollectionValueItem>
}

export type AttributeCollectionValueInput = {
    attributeID: Scalars['ID']['input']
    value: Array<AttributeCollectionValueItemInput>
}

export type AttributeCollectionValueItem = {
    __typename?: 'AttributeCollectionValueItem'
    attributeAssetValues: Array<AttributeAssetValue>
    attributeBooleanValues: Array<AttributeBooleanValue>
    attributeLinkValues: Array<AttributeLinkValue>
    attributeNumberValues: Array<AttributeNumberValue>
    attributeSelectionValues: Array<AttributeSelectionValue>
    attributeTextValues: Array<AttributeTextValue>
    id: Scalars['ID']['output']
}

export type AttributeCollectionValueItemInput = {
    attributeAssetValues: Array<AttributeAssetValueInput>
    attributeBooleanValues: Array<AttributeBooleanValueInput>
    attributeLinkValues: Array<AttributeLinkValueInput>
    attributeNumberValues: Array<AttributeNumberValueInput>
    attributeSelectionValues: Array<AttributeSelectionValueInput>
    attributeTextValues: Array<AttributeTextValueInput>
    id: Scalars['ID']['input']
}

export type AttributeCommon = {
    __typename?: 'AttributeCommon'
    _next?: Maybe<Scalars['ID']['output']>
    description: Scalars['String']['output']
    identifier: Scalars['String']['output']
    name: Scalars['String']['output']
    visible: Scalars['Boolean']['output']
}

export type AttributeCommonInput = {
    description: Scalars['String']['input']
    identifier: Scalars['String']['input']
    name: Scalars['String']['input']
    visible: Scalars['Boolean']['input']
}

export type AttributeLink = {
    __typename?: 'AttributeLink'
    allowed: AttributeLinkAllowedOptions
    common: AttributeCommon
    id: Scalars['ID']['output']
}

export enum AttributeLinkAllowedOptions {
    EXTERNAL = 'EXTERNAL',
    EXTERNAL_FILE = 'EXTERNAL_FILE',
    FILE = 'FILE',
    INTERNAL = 'INTERNAL',
    INTERNAL_EXTERNAL = 'INTERNAL_EXTERNAL',
    INTERNAL_EXTERNAL_FILE = 'INTERNAL_EXTERNAL_FILE',
    INTERNAL_FILE = 'INTERNAL_FILE',
}

export type AttributeLinkInput = {
    allowed: AttributeLinkAllowedOptions
    common: AttributeCommonInput
    id: Scalars['ID']['input']
}

export type AttributeLinkValue = {
    __typename?: 'AttributeLinkValue'
    attributeID: Scalars['ID']['output']
    newWindow: Scalars['Boolean']['output']
    value: Scalars['String']['output']
}

export type AttributeLinkValueInput = {
    attributeID: Scalars['ID']['input']
    newWindow: Scalars['Boolean']['input']
    value: Scalars['String']['input']
}

export type AttributeNumber = {
    __typename?: 'AttributeNumber'
    common: AttributeCommon
    defaultValue?: Maybe<Scalars['Float']['output']>
    id: Scalars['ID']['output']
    integer?: Maybe<Scalars['Boolean']['output']>
    max?: Maybe<Scalars['Float']['output']>
    min?: Maybe<Scalars['Float']['output']>
}

export type AttributeNumberInput = {
    common: AttributeCommonInput
    defaultValue?: InputMaybe<Scalars['Float']['input']>
    id: Scalars['ID']['input']
    integer?: InputMaybe<Scalars['Boolean']['input']>
    max?: InputMaybe<Scalars['Float']['input']>
    min?: InputMaybe<Scalars['Float']['input']>
}

export type AttributeNumberValue = {
    __typename?: 'AttributeNumberValue'
    attributeID: Scalars['ID']['output']
    value: Scalars['Float']['output']
}

export type AttributeNumberValueInput = {
    attributeID: Scalars['ID']['input']
    value: Scalars['Float']['input']
}

export type AttributeSelection = {
    __typename?: 'AttributeSelection'
    common: AttributeCommon
    defaultValue?: Maybe<Scalars['ID']['output']>
    id: Scalars['ID']['output']
    options: Array<AttributeSelectionOption>
}

export type AttributeSelectionInput = {
    common: AttributeCommonInput
    defaultValue?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    options: Array<AttributeSelectionOptionInput>
}

export type AttributeSelectionOption = {
    __typename?: 'AttributeSelectionOption'
    description: Scalars['String']['output']
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
}

export type AttributeSelectionOptionInput = {
    description: Scalars['String']['input']
    id: Scalars['ID']['input']
    name: Scalars['String']['input']
}

export type AttributeSelectionValue = {
    __typename?: 'AttributeSelectionValue'
    attributeID: Scalars['ID']['output']
    value: Scalars['ID']['output']
}

export type AttributeSelectionValueInput = {
    attributeID: Scalars['ID']['input']
    value: Scalars['ID']['input']
}

export type AttributeText = {
    __typename?: 'AttributeText'
    common: AttributeCommon
    defaultValue?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    multiLine?: Maybe<Scalars['Boolean']['output']>
}

export type AttributeTextInput = {
    common: AttributeCommonInput
    defaultValue?: InputMaybe<Scalars['String']['input']>
    id: Scalars['ID']['input']
    multiLine?: InputMaybe<Scalars['Boolean']['input']>
}

export type AttributeTextValue = {
    __typename?: 'AttributeTextValue'
    attributeID: Scalars['ID']['output']
    value: Scalars['String']['output']
}

export type AttributeTextValueInput = {
    attributeID: Scalars['ID']['input']
    value: Scalars['String']['input']
}

export type Block = {
    __typename?: 'Block'
    createdAt: Scalars['Int']['output']
    currentVersion: BlockVersion
    deletedAt?: Maybe<Scalars['Int']['output']>
    historyVersions: Array<BlockVersion>
    id: Scalars['ID']['output']
    identifier: Scalars['String']['output']
}

export type BlockCreateInput = {
    name: Scalars['String']['input']
    websiteID: Scalars['ID']['input']
}

export type BlockSaveInput = {
    blockID: Scalars['ID']['input']
    content: Scalars['String']['input']
    identifier: Scalars['String']['input']
    name: Scalars['String']['input']
}

export type BlockVersion = {
    __typename?: 'BlockVersion'
    blockID: Scalars['ID']['output']
    contents: Scalars['String']['output']
    createdAt: Scalars['Int']['output']
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    state: State
    updatedAt: Scalars['Int']['output']
    updatedBy: User
}

export type CreatePageInput = {
    parentPageID?: InputMaybe<Scalars['ID']['input']>
    title: Scalars['String']['input']
    websiteID: Scalars['ID']['input']
}

export type DataItem = {
    __typename?: 'DataItem'
    attributeAssetValues: Array<AttributeAssetValue>
    attributeBooleanValues: Array<AttributeBooleanValue>
    attributeCollectionValues: Array<AttributeCollectionValue>
    attributeLinkValues: Array<AttributeLinkValue>
    attributeNumberValues: Array<AttributeNumberValue>
    attributeSelectionValues: Array<AttributeSelectionValue>
    attributeTextValues: Array<AttributeTextValue>
    created: DataItemChange
    id: Scalars['ID']['output']
    published: Scalars['Boolean']['output']
    updated: DataItemChange
}

export type DataItemChange = {
    __typename?: 'DataItemChange'
    changeType: DataItemChangeType
    timestamp: Scalars['Int']['output']
    user: User
}

export enum DataItemChangeType {
    CREATE = 'CREATE',
    PUBLISH = 'PUBLISH',
    UNPUBLISH = 'UNPUBLISH',
    UPDATE = 'UPDATE',
}

export type DataItemInput = {
    attributeAssetValues: Array<AttributeAssetValueInput>
    attributeBooleanValues: Array<AttributeBooleanValueInput>
    attributeCollectionValues: Array<AttributeCollectionValueInput>
    attributeLinkValues: Array<AttributeLinkValueInput>
    attributeNumberValues: Array<AttributeNumberValueInput>
    attributeSelectionValues: Array<AttributeSelectionValueInput>
    attributeTextValues: Array<AttributeTextValueInput>
    id: Scalars['ID']['input']
    published: Scalars['Boolean']['input']
}

export type DataItemPublishInput = {
    id: Scalars['ID']['input']
    published: Scalars['Boolean']['input']
}

export type DataType = {
    __typename?: 'DataType'
    _head?: Maybe<Scalars['ID']['output']>
    attributeAssetList: Array<AttributeAsset>
    attributeBooleanList: Array<AttributeBoolean>
    attributeCollectionList: Array<AttributeCollection>
    attributeLinkList: Array<AttributeLink>
    attributeNumberList: Array<AttributeNumber>
    attributeSelectionList: Array<AttributeSelection>
    attributeTextList: Array<AttributeText>
    category: DataTypeCategory
    created: DataTypeChange
    description: Scalars['String']['output']
    id: Scalars['ID']['output']
    identifier: Scalars['String']['output']
    name: Scalars['String']['output']
    updated: DataTypeChange
}

export enum DataTypeAttributeType {
    ASSET = 'ASSET',
    BOOLEAN = 'BOOLEAN',
    COLLECTION = 'COLLECTION',
    LINK = 'LINK',
    NUMBER = 'NUMBER',
    SELECTION = 'SELECTION',
    TEXT = 'TEXT',
}

export enum DataTypeCategory {
    DATA_MODULE = 'DATA_MODULE',
    ELEMENT_DEFINITION = 'ELEMENT_DEFINITION',
    SUB_TYPE = 'SUB_TYPE',
}

export type DataTypeChange = {
    __typename?: 'DataTypeChange'
    changeType: DataTypeChangeType
    timestamp: Scalars['Int']['output']
    user: User
}

export enum DataTypeChangeType {
    CREATE = 'CREATE',
    CREATE_ATTRIBUTE = 'CREATE_ATTRIBUTE',
    CREATE_ITEM = 'CREATE_ITEM',
    DELETE_ITEM = 'DELETE_ITEM',
    REMOVE_ATTRIBUTE = 'REMOVE_ATTRIBUTE',
    REORDER_ATTRIBUTES = 'REORDER_ATTRIBUTES',
    SET_PUBLISHED = 'SET_PUBLISHED',
    UPDATE = 'UPDATE',
    UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE',
    UPDATE_ITEM = 'UPDATE_ITEM',
}

export type DataTypeInput = {
    category: DataTypeCategory
    description: Scalars['String']['input']
    id: Scalars['ID']['input']
    identifier: Scalars['String']['input']
    name: Scalars['String']['input']
}

export type DataTypeWithItems = {
    __typename?: 'DataTypeWithItems'
    dataItems: Array<DataItem>
    dataType: DataType
}

export type DuplicatePageInput = {
    pageID: Scalars['ID']['input']
}

export type ElementDefinition = {
    __typename?: 'ElementDefinition'
    fields: Array<Maybe<Scalars['String']['output']>>
    identifier: Scalars['String']['output']
    name: Scalars['String']['output']
}

export type ErrorConfig = {
    __typename?: 'ErrorConfig'
    content: Scalars['String']['output']
    messages?: Maybe<Array<Scalars['String']['output']>>
    title: Scalars['String']['output']
}

export type FolderCreateInput = {
    name: Scalars['String']['input']
    parentFolderID?: InputMaybe<Scalars['ID']['input']>
    websiteID: Scalars['ID']['input']
}

export type FolderUpdateInput = {
    id: Scalars['ID']['input']
    name: Scalars['String']['input']
    parentFolderID?: InputMaybe<Scalars['ID']['input']>
    tags?: InputMaybe<Array<Scalars['String']['input']>>
}

export enum Frequency {
    ALWAYS = 'ALWAYS',
    DAILY = 'DAILY',
    HOURLY = 'HOURLY',
    MONTHLY = 'MONTHLY',
    NEVER = 'NEVER',
    WEEKLY = 'WEEKLY',
    YEARLY = 'YEARLY',
}

export type LoggedInUser = {
    __typename?: 'LoggedInUser'
    defaultWebsiteID: Scalars['ID']['output']
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
}

export type Mutation = {
    __typename?: 'Mutation'
    assetDelete?: Maybe<Response>
    assetUpdate: Asset
    assetUpload: Asset
    blockCreate: Block
    blockDelete?: Maybe<Response>
    blockDuplicate: Block
    blockSave: Block
    createPage: Page
    dataItemBatchSetPublished: Scalars['Boolean']['output']
    dataItemCreate: Scalars['ID']['output']
    dataItemDelete: Scalars['ID']['output']
    dataItemUpdate: Scalars['ID']['output']
    dataTypeCreate: Scalars['ID']['output']
    dataTypeCreateAttributeAsset: Scalars['ID']['output']
    dataTypeCreateAttributeBoolean: Scalars['ID']['output']
    dataTypeCreateAttributeCollection: Scalars['ID']['output']
    dataTypeCreateAttributeLink: Scalars['ID']['output']
    dataTypeCreateAttributeNumber: Scalars['ID']['output']
    dataTypeCreateAttributeSelection: Scalars['ID']['output']
    dataTypeCreateAttributeText: Scalars['ID']['output']
    dataTypeDelete: Scalars['ID']['output']
    dataTypeRemoveAttribute: Scalars['ID']['output']
    dataTypeReorderAttributes: Scalars['ID']['output']
    dataTypeUpdate: Scalars['ID']['output']
    dataTypeUpdateAttributeAsset: Scalars['ID']['output']
    dataTypeUpdateAttributeBoolean: Scalars['ID']['output']
    dataTypeUpdateAttributeCollection: Scalars['ID']['output']
    dataTypeUpdateAttributeLink: Scalars['ID']['output']
    dataTypeUpdateAttributeNumber: Scalars['ID']['output']
    dataTypeUpdateAttributeSelection: Scalars['ID']['output']
    dataTypeUpdateAttributeText: Scalars['ID']['output']
    deactivatePage?: Maybe<Response>
    deletePage?: Maybe<Response>
    duplicatePage: Page
    folderCreate: AssetFolder
    folderDelete?: Maybe<Response>
    folderUpdate: AssetFolder
    pageTreeReorder?: Maybe<Response>
    publishPages?: Maybe<Response>
    savePage: SavePageResponse
    websiteCreate: Website
    websiteSave: Website
}

export type MutationAssetDeleteArgs = {
    id: Scalars['ID']['input']
}

export type MutationAssetUpdateArgs = {
    input: AssetUpdateInput
}

export type MutationAssetUploadArgs = {
    input: AssetUploadInput
}

export type MutationBlockCreateArgs = {
    input: BlockCreateInput
}

export type MutationBlockDeleteArgs = {
    ids: Array<Scalars['String']['input']>
}

export type MutationBlockDuplicateArgs = {
    blockID: Scalars['ID']['input']
}

export type MutationBlockSaveArgs = {
    input: BlockSaveInput
}

export type MutationCreatePageArgs = {
    input: CreatePageInput
}

export type MutationDataItemBatchSetPublishedArgs = {
    input: Array<DataItemPublishInput>
}

export type MutationDataItemCreateArgs = {
    dataTypeID: Scalars['ID']['input']
    input: DataItemInput
}

export type MutationDataItemDeleteArgs = {
    id: Scalars['ID']['input']
}

export type MutationDataItemUpdateArgs = {
    input: DataItemInput
}

export type MutationDataTypeCreateArgs = {
    input: DataTypeInput
    website: Scalars['ID']['input']
}

export type MutationDataTypeCreateAttributeAssetArgs = {
    dataTypeID: Scalars['ID']['input']
    input: AttributeAssetInput
}

export type MutationDataTypeCreateAttributeBooleanArgs = {
    dataTypeID: Scalars['ID']['input']
    input: AttributeBooleanInput
}

export type MutationDataTypeCreateAttributeCollectionArgs = {
    dataTypeID: Scalars['ID']['input']
    input: AttributeCollectionInput
}

export type MutationDataTypeCreateAttributeLinkArgs = {
    dataTypeID: Scalars['ID']['input']
    input: AttributeLinkInput
}

export type MutationDataTypeCreateAttributeNumberArgs = {
    dataTypeID: Scalars['ID']['input']
    input: AttributeNumberInput
}

export type MutationDataTypeCreateAttributeSelectionArgs = {
    dataTypeID: Scalars['ID']['input']
    input: AttributeSelectionInput
}

export type MutationDataTypeCreateAttributeTextArgs = {
    dataTypeID: Scalars['ID']['input']
    input: AttributeTextInput
}

export type MutationDataTypeDeleteArgs = {
    id: Scalars['ID']['input']
}

export type MutationDataTypeRemoveAttributeArgs = {
    id: Scalars['ID']['input']
}

export type MutationDataTypeReorderAttributesArgs = {
    order: Array<Scalars['ID']['input']>
}

export type MutationDataTypeUpdateArgs = {
    input: DataTypeInput
}

export type MutationDataTypeUpdateAttributeAssetArgs = {
    input: AttributeAssetInput
}

export type MutationDataTypeUpdateAttributeBooleanArgs = {
    input: AttributeBooleanInput
}

export type MutationDataTypeUpdateAttributeCollectionArgs = {
    input: AttributeCollectionInput
}

export type MutationDataTypeUpdateAttributeLinkArgs = {
    input: AttributeLinkInput
}

export type MutationDataTypeUpdateAttributeNumberArgs = {
    input: AttributeNumberInput
}

export type MutationDataTypeUpdateAttributeSelectionArgs = {
    input: AttributeSelectionInput
}

export type MutationDataTypeUpdateAttributeTextArgs = {
    input: AttributeTextInput
}

export type MutationDeactivatePageArgs = {
    pageID: Scalars['ID']['input']
}

export type MutationDeletePageArgs = {
    pageID: Scalars['ID']['input']
}

export type MutationDuplicatePageArgs = {
    input: DuplicatePageInput
}

export type MutationFolderCreateArgs = {
    input: FolderCreateInput
}

export type MutationFolderDeleteArgs = {
    id: Scalars['ID']['input']
}

export type MutationFolderUpdateArgs = {
    input: FolderUpdateInput
}

export type MutationPageTreeReorderArgs = {
    input: Array<OrderItemInput>
}

export type MutationPublishPagesArgs = {
    input: Array<PublishItem>
}

export type MutationSavePageArgs = {
    input: SavePageInput
}

export type MutationWebsiteCreateArgs = {
    input: WebsiteInput
}

export type MutationWebsiteSaveArgs = {
    input: WebsiteInput
}

export type OrderItemInput = {
    id: Scalars['ID']['input']
    parent?: InputMaybe<Scalars['ID']['input']>
}

export type Page = {
    __typename?: 'Page'
    createdAt: Scalars['Int']['output']
    currentVersion: PageVersion
    deactivated: Scalars['Boolean']['output']
    deletedAt?: Maybe<Scalars['Int']['output']>
    historyVersions: Array<PageVersion>
    id: Scalars['ID']['output']
    parentPageIdentifier?: Maybe<Scalars['ID']['output']>
    sortIndex: Scalars['Int']['output']
    updatedAt: Scalars['Int']['output']
}

export enum PageRank {
    EIGHT = 'EIGHT',
    FIVE = 'FIVE',
    FOUR = 'FOUR',
    NINE = 'NINE',
    ONE = 'ONE',
    SEVEN = 'SEVEN',
    SIX = 'SIX',
    TEN = 'TEN',
    THREE = 'THREE',
    TWO = 'TWO',
}

export type PageSettingInput = {
    changeFrequency: Frequency
    htmlTitle: Scalars['String']['input']
    includeInSitemapXML: Scalars['Boolean']['input']
    language: Scalars['String']['input']
    linkedLanguagePagesIds?: InputMaybe<Array<Scalars['String']['input']>>
    metaDescription: Scalars['String']['input']
    metaKeywords: Scalars['String']['input']
    pageRank: PageRank
    robotsOption: RobotsOption
    translationID?: InputMaybe<Scalars['String']['input']>
    urlTitle: Scalars['String']['input']
}

export type PageSettings = {
    __typename?: 'PageSettings'
    changeFrequency: Frequency
    htmlTitle: Scalars['String']['output']
    includeInSitemapXML: Scalars['Boolean']['output']
    language: Scalars['String']['output']
    metaDescription: Scalars['String']['output']
    metaKeywords: Scalars['String']['output']
    pageRank: PageRank
    robotsOption: RobotsOption
    translationID?: Maybe<Scalars['String']['output']>
    urlTitle: Scalars['String']['output']
}

export type PageVersion = {
    __typename?: 'PageVersion'
    ID: Scalars['String']['output']
    contents: Scalars['String']['output']
    createdAt: Scalars['Int']['output']
    pageID: Scalars['String']['output']
    pageSettings: PageSettings
    state: State
    updatedAt: Scalars['Int']['output']
    updatedBy: User
}

export type PublishItem = {
    itemID: Scalars['ID']['input']
    type: Scalars['String']['input']
}

export type Query = {
    __typename?: 'Query'
    assets: AssetResponse
    dataItemGet: Array<DataItem>
    dataTypeGet: Array<DataType>
    dataTypeGetWithItems: Array<DataTypeWithItems>
    loadBlock: Block
    loadBlocks: Array<Block>
    loadMe?: Maybe<LoggedInUser>
    loadPage: Page
    loadPages: Array<Page>
    loadWebsite: Website
    loadWebsites: Array<Website>
}

export type QueryAssetsArgs = {
    websiteID: Scalars['ID']['input']
}

export type QueryDataItemGetArgs = {
    dataTypeID: Scalars['ID']['input']
}

export type QueryDataTypeGetArgs = {
    category: DataTypeCategory
    website: Scalars['ID']['input']
}

export type QueryDataTypeGetWithItemsArgs = {
    category: DataTypeCategory
    website: Scalars['ID']['input']
}

export type QueryLoadBlockArgs = {
    blockID: Scalars['ID']['input']
}

export type QueryLoadBlocksArgs = {
    websiteID: Scalars['ID']['input']
}

export type QueryLoadPageArgs = {
    pageID: Scalars['ID']['input']
}

export type QueryLoadPagesArgs = {
    websiteID: Scalars['ID']['input']
}

export type QueryLoadWebsiteArgs = {
    websiteID: Scalars['ID']['input']
}

export type Response = {
    __typename?: 'Response'
    statusCode: Scalars['Int']['output']
    success: Scalars['Boolean']['output']
}

export enum RobotsOption {
    BOTH = 'BOTH',
    FOLLOW = 'FOLLOW',
    INDEX = 'INDEX',
    NONE = 'NONE',
}

export type SavePageInput = {
    content?: InputMaybe<Scalars['String']['input']>
    pageID: Scalars['ID']['input']
    pageSettings?: InputMaybe<PageSettingInput>
}

export type SavePageResponse = {
    __typename?: 'SavePageResponse'
    linkedPages: Array<Page>
    page: Page
}

export enum State {
    DELETED = 'DELETED',
    PUBLISHED = 'PUBLISHED',
    UNPUBLISHED = 'UNPUBLISHED',
}

export type User = {
    __typename?: 'User'
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
}

export type Website = {
    __typename?: 'Website'
    createdAt: Scalars['Int']['output']
    elementDefinitions: Scalars['String']['output']
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    previewURL: Scalars['String']['output']
    updatedAt: Scalars['Int']['output']
    updatedBy: User
}

export type WebsiteInput = {
    createdAt: Scalars['Int']['input']
    elementDefinitions: Scalars['String']['input']
    id: Scalars['ID']['input']
    name: Scalars['String']['input']
    previewURL: Scalars['String']['input']
}
