import { Add, Close, List } from '@mui/icons-material'
import { Box, Grid, IconButton, Stack, Tooltip } from '@mui/material'
import PageTree from 'components/PageTree'
import { PageTreeEntry, Status } from 'components/PageTree/PageTree'
import { Page, State } from 'graphql/types'

export interface PageTreePanelProps {
    pages: Page[]
    expanded: string[]
    onSetExpanded: (ids: string[]) => void
    selectedPage: string
    onSelectPage: (id: string) => void
    switchToSortMode?: () => void
    cancel?: () => void
    onPageCreate?: () => void
}

const getStatus = (page: Page): Status => {
    if (page.deactivated) {
        return Status.DEACTIVATED
    } else if (page.currentVersion.state === State.UNPUBLISHED) {
        return Status.NOT_PUBLISHED
        //TODO: get version history from the page tree load. Maybe load all infos from the page? So we don't need pageLoad?
        // if(true){
        //     return Status.NOT_PUBLISHED
        // }else{
        //     return Status.PUBLISHED_WITH_CHANGES
        // }
    } else if (page.currentVersion.state === State.PUBLISHED) {
        return Status.PUBLISHED_NO_CHANGES
    }

    return Status.PUBLISHED_NO_CHANGES
}

const convertPageListToPageTree = (pageList: Page[], currentPage: Page | null): PageTreeEntry[] => {
    const pageTree: PageTreeEntry[] = []

    pageList.forEach((page: Page) => {
        const parentID: string = currentPage ? currentPage.id : ''
        if (page.parentPageIdentifier === parentID) {
            const pageTreeEntry: PageTreeEntry = {
                id: page.id,
                name: page.currentVersion.pageSettings.htmlTitle,
                status: getStatus(page),
                subpages: convertPageListToPageTree(pageList, page),
            }
            pageTree.push(pageTreeEntry)
        }
    })
    return pageTree
}

const PageTreePanel = (props: PageTreePanelProps): JSX.Element => {
    const { pages, selectedPage, expanded, onSetExpanded, onPageCreate, onSelectPage, switchToSortMode, cancel } = props

    return (
        <Box display="flex" flexDirection="column" flex={1} height="100%">
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                {cancel && (
                    <Stack justifyContent={'flex-end'} direction={'row'} style={{ width: '100%' }}>
                        <IconButton aria-label="cancel" onClick={cancel}>
                            <Close />
                        </IconButton>
                    </Stack>
                )}
            </Grid>
            <PageTree
                pages={convertPageListToPageTree(pages, null)}
                onPageSelect={onSelectPage}
                selectedPage={selectedPage}
                expanded={expanded}
                onSetExpanded={onSetExpanded}
            />
            <Box marginTop="auto" display="flex" marginLeft="auto">
                {onPageCreate !== undefined && (
                    <Tooltip title="Create new page">
                        <IconButton onClick={onPageCreate}>
                            <Add />
                        </IconButton>
                    </Tooltip>
                )}
                {switchToSortMode !== undefined && (
                    <Tooltip title="Reorder pages">
                        <IconButton onClick={switchToSortMode}>
                            <List />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </Box>
    )
}

export default PageTreePanel
