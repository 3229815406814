import { MutationHookOptions, useMutation, useQuery } from '@apollo/client'
import {
    DATA_ITEM_BATCH_SET_PUBLISHED,
    DATA_ITEM_CREATE,
    DATA_ITEM_DELETE,
    DATA_ITEM_UPDATE,
} from 'graphql/mutations/dataItem'
import { DATA_TYPE_GET, DATA_TYPE_GET_WITH_ITEMS } from 'graphql/queries/dataTypeGet'
import {
    DataType,
    DataTypeCategory,
    DataTypeWithItems,
    Mutation,
    MutationDataItemBatchSetPublishedArgs,
    MutationDataItemCreateArgs,
    MutationDataItemDeleteArgs,
    MutationDataItemUpdateArgs,
    Page,
    Query,
    QueryDataTypeGetArgs,
    QueryDataTypeGetWithItemsArgs,
} from 'graphql/types'
import { useEffect, useState } from 'react'
import { useStoreState } from 'store/hooks'
import {
    getDataItemUnionList,
    getDiscriminatedItemFromAttributeValueUnion,
    getOrderedAttributeUnion,
} from 'utils/dataType/functions'
import { AttributeUnion, DataItemUnion, createDefaultDataItem, getDataItemInputFromType } from 'utils/dataType/types'
import { AssetSimplified, UnpublishedItem } from 'utils/types'
import { v4 } from 'uuid'

export interface UseDataItemPageReturn {
    // data
    dataTypesWithItems: DataTypeWithItems[]
    dataTypes: DataType[] // generated from the combo type above
    items: DataItemUnion[] // generated from the combo type above
    attributes: AttributeUnion[] // built from the selectedType
    subTypes: DataType[] // for attributeCollection
    unpublishedItems: UnpublishedItem[]
    assets: AssetSimplified[]
    pages: Page[]

    // selection
    selectedType: DataType | undefined
    setSelectedType: (item: DataType) => void
    selectedItem: DataItemUnion | undefined
    setSelectedItem: (item: DataItemUnion) => void

    // actions
    onClickType: (item: DataType) => void

    onClickItem: (item: DataItemUnion) => void
    onClickCreateItem: () => void
    onClickSaveItem: () => void
    onClickCancelItem: () => void
    onClickDeleteItem: (item: DataItemUnion) => void
    onTogglePublishItem: (item: DataItemUnion) => void
    onClickPublishAll: () => void
}

const mutationOptions: Omit<MutationHookOptions, 'variables'> = {
    refetchQueries: [DATA_TYPE_GET_WITH_ITEMS],
}

export const useDataItemPage = (): UseDataItemPageReturn => {
    // global state
    const website = useStoreState((state) => state.model.selectedWebsite)?.id ?? ''
    const unpublishedItems = useStoreState((state) => state.model.unpublishedItems)
    const assets = useStoreState((state) => state.model.assetsSimplified)
    const pages = useStoreState((state) => state.model.pageList)

    // local state
    const [selectedType, setSelectedType] = useState<DataType>()
    const [attributes, setAttributes] = useState<AttributeUnion[]>([])
    const [selectedItem, setSelectedItem] = useState<DataItemUnion>()
    const [items, setItems] = useState<DataItemUnion[]>([])

    // queries
    const { data } = useQuery<Query, QueryDataTypeGetWithItemsArgs>(DATA_TYPE_GET_WITH_ITEMS, {
        variables: { category: DataTypeCategory.DATA_MODULE, website },
        fetchPolicy: 'network-only',
    })
    const dataTypesWithItems = data?.dataTypeGetWithItems ?? []

    const dataTypes = dataTypesWithItems.map((item) => item.dataType)
    const dataItems = dataTypesWithItems.find((item) => item.dataType.id === selectedType?.id)?.dataItems ?? []

    const { data: dataSubTypesGet } = useQuery<Query, QueryDataTypeGetArgs>(DATA_TYPE_GET, {
        variables: {
            category: DataTypeCategory.SUB_TYPE,
            website,
        },
        fetchPolicy: 'network-only',
    })
    const subTypes = dataSubTypesGet?.dataTypeGet ?? []

    // useEffects
    // default select first
    useEffect(() => {
        if (!selectedType) setSelectedType(dataTypes.at(0))
        else setSelectedType(dataTypes.find((item) => item.id === selectedType.id))
    }, [dataTypes])

    // build attributes from linked list
    useEffect(() => {
        if (selectedType) setAttributes(getOrderedAttributeUnion(selectedType))
    }, [selectedType])

    // reset on type change
    useEffect(() => setSelectedItem(undefined), [selectedType?.id])

    // build items from attributes and dataItems
    useEffect(() => {
        setItems(getDataItemUnionList(attributes, dataItems))
    }, [attributes, dataItems])

    // mutations
    const [createDataItem] = useMutation<Mutation, MutationDataItemCreateArgs>(DATA_ITEM_CREATE, {
        ...mutationOptions,
        onCompleted: (data) => selectedItem && setSelectedItem({ ...selectedItem, id: data.dataItemCreate }),
    })
    const [updateDataItem] = useMutation<Mutation, MutationDataItemUpdateArgs>(DATA_ITEM_UPDATE, mutationOptions)
    const [deleteDataItem] = useMutation<Mutation, MutationDataItemDeleteArgs>(DATA_ITEM_DELETE, {
        ...mutationOptions,
        onCompleted: (data) => {
            if (selectedItem?.id === data.dataItemDelete) setSelectedItem(undefined)
        },
    })
    const [setPublishedDataItem] = useMutation<Mutation, MutationDataItemBatchSetPublishedArgs>(
        DATA_ITEM_BATCH_SET_PUBLISHED,
        mutationOptions,
    )

    // functions
    // TODO: handle draft confirm discard
    const onClickType = (item: DataType) => setSelectedType(item)

    const onClickItem = (item: DataItemUnion) => setSelectedItem(item)
    const onClickCreateItem = () => setSelectedItem(createDefaultDataItem(attributes))

    const onClickSaveItem = () => {
        if (selectedType && selectedItem) {
            const item = getDiscriminatedItemFromAttributeValueUnion(selectedItem)
            const input = getDataItemInputFromType(item)
            if (input.id === '') {
                input.id = v4()
                createDataItem({ variables: { dataTypeID: selectedType.id, input } })
            } else updateDataItem({ variables: { input } })
            setSelectedItem(undefined)
        }
    }
    const onClickCancelItem = () => setSelectedItem(undefined)
    const onClickDeleteItem = (item: DataItemUnion) => deleteDataItem({ variables: { id: item.id } })
    const onTogglePublishItem = (item: DataItemUnion) =>
        setPublishedDataItem({ variables: { input: [{ id: item.id, published: !item.published }] } })
    const onClickPublishAll = () =>
        setPublishedDataItem({
            variables: {
                input: items.filter((item) => !item.published).map((item) => ({ id: item.id, published: true })),
            },
        })

    return {
        unpublishedItems,
        assets,
        pages,
        items,
        subTypes,
        dataTypes,
        dataTypesWithItems,
        attributes,
        selectedItem,
        selectedType,
        setSelectedItem,
        setSelectedType,
        onClickCancelItem,
        onClickCreateItem,
        onClickDeleteItem,
        onClickItem,
        onClickSaveItem,
        onClickType,
        onTogglePublishItem,
        onClickPublishAll,
    }
}
