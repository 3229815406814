import AddCircleIcon from '@mui/icons-material/AddCircle'
import MenuIcon from '@mui/icons-material/Menu'
import PublishIcon from '@mui/icons-material/Publish'
import SettingsIcon from '@mui/icons-material/Settings'
import { Badge, Button, FormControl, InputLabel, Select } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import colors from 'utils/colors'
import { logout } from 'utils/logout'
import { NavigationRoutes } from 'utils/navigationRoutes'
import { UnpublishedItem, WebsiteOption } from 'utils/types'

export enum CMSTab {
    PAGES,
    ASSETS,
    DATA,
    TEMPLATE,
    SETTINGS,
    PUBLISH,
    CREATE_WEBSITE,
}

interface Page {
    title: string
    route: NavigationRoutes
    icon?: (props: CMSHeaderProps & { selected: boolean }) => JSX.Element
}

type IconPage = Page & {
    icon: (props: CMSHeaderProps & { selected: boolean }) => JSX.Element
}

const pages: Page[] = [
    { title: 'Pages', route: NavigationRoutes.PAGE_TREE },
    { title: 'Blocks', route: NavigationRoutes.BLOCK_LIST },
    { title: 'Assets', route: NavigationRoutes.ASSETS },
    { title: 'Data', route: NavigationRoutes.DATA_ITEMS },
]

const iconPages: IconPage[] = [
    {
        title: 'Publish',
        route: NavigationRoutes.PUBLISH,
        icon: (props) => (
            <Badge
                badgeContent={props.unpublishedItems.length}
                sx={{
                    '& .MuiBadge-badge': {
                        color: props.selected ? colors.white : colors.black,
                        backgroundColor: props.selected ? colors.primary : colors.secondary,
                    },
                }}
            >
                <PublishIcon
                    style={{
                        color: props.selected ? colors.primary : colors.black,
                    }}
                />
            </Badge>
        ),
    },
    {
        title: 'Add Website',
        route: NavigationRoutes.WEBSITE_ADD,
        icon: (props) => <AddCircleIcon style={{ color: props.selected ? colors.primary : colors.black }} />,
    },
    {
        title: 'Settings',
        route: NavigationRoutes.SETTINGS,
        icon: (props) => <SettingsIcon style={{ color: props.selected ? colors.primary : colors.black }} />,
    },
]

const ProfileClick = () => {
    console.log('onProfileClick')
}

const LogoutClick = async () => {
    const token = document.cookie
        .split('; ')
        .find((row) => row.startsWith('token='))
        ?.split('=')[1]
    if (token) {
        await logout(token)
    }
    // call api then reroute alright
}

const settings = [
    { name: 'Profile', action: ProfileClick },
    { name: 'Logout', action: LogoutClick },
]

export interface CMSHeaderProps {
    unpublishedItems: UnpublishedItem[]
    tabNavigation?: boolean
    websiteList: WebsiteOption[]
    selectedWebsite: string
    onSelectWebsite: (websiteID: string) => void
}

const iconBtnHeader = { my: 2, color: 'black' }

const CMSHeader = (props: CMSHeaderProps) => {
    const { tabNavigation, selectedWebsite, onSelectWebsite, websiteList } = props

    const navigate = useNavigate()
    const location = useLocation()

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget)
    const handleCloseNavMenu = () => setAnchorElNav(null)

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget)
    const handleCloseUserMenu = () => setAnchorElUser(null)

    const onClickPage = (page: Page) => page.route !== location.pathname && navigate(page.route)

    return (
        <>
            <AppBar position="fixed" sx={{ backgroundColor: 'white' }}>
                <Toolbar>
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            maxWidth: 120,
                            paddingRight: 2,
                        }}
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/qubiduLogo.svg'}
                            style={{ maxWidth: 120 }}
                            alt={'Qubidu'}
                            loading="lazy"
                        />
                    </Box>

                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <Tooltip title="Open navigation">
                            <IconButton size="large" onClick={handleOpenNavMenu} sx={{ color: 'black' }}>
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {[...pages, ...iconPages].map((page) => (
                                <MenuItem key={page.title} onClick={() => onClickPage(page)}>
                                    <Typography>{page.title}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' },
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/qubiduLogo.svg'}
                            style={{ maxWidth: 90 }}
                            alt={'Qubidu'}
                            loading="lazy"
                        />
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <FormControl sx={{ m: 1, minWidth: 125, maxWidth: 200 }}>
                            <InputLabel id="website-select">Webseiten</InputLabel>
                            <Select
                                labelId="website-select"
                                id="website-select"
                                value={selectedWebsite}
                                onChange={(event) => onSelectWebsite(event.target.value)}
                                autoWidth
                                label="Webseiten"
                            >
                                {websiteList.map((website) => (
                                    <MenuItem key={website.id} value={website.id}>
                                        {website.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {pages.map((page) => (
                            <Button
                                key={page.title}
                                onClick={() => onClickPage(page)}
                                sx={{
                                    my: 2,
                                    color: page.route === location.pathname ? '#5eafc7' : 'black',
                                    display: 'block',
                                }}
                            >
                                <Typography textAlign="center" color="inherit" fontWeight={700}>
                                    {page.title}
                                </Typography>
                            </Button>
                        ))}
                        <Box marginLeft={'auto'} />
                        {iconPages.map((page) => (
                            <Tooltip key={page.title} title={page.title}>
                                <IconButton
                                    color="inherit"
                                    sx={iconBtnHeader}
                                    component="div"
                                    onClick={() => onClickPage(page)}
                                >
                                    {page.icon({ ...props, selected: page.route === location.pathname })}
                                </IconButton>
                            </Tooltip>
                        ))}
                    </Box>

                    <Box sx={{ marginLeft: '16px', flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center" onClick={setting.action}>
                                        {setting.name}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            {!tabNavigation && <div style={{ height: '72px' }}></div>}
        </>
    )
}

export default CMSHeader
