import { Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { AttributeLink, AttributeLinkAllowedOptions } from 'graphql/types'
import { AttributeEditProps } from './AttributeCommonEdit'

export const AttributeLinkEdit = (props: AttributeEditProps) => {
    const { selectedAttribute, setSelectedAttribute } = props

    if (selectedAttribute.__typename !== 'AttributeLink') return null

    const { allowed } = selectedAttribute

    const override = (values: Partial<AttributeLink>) =>
        setSelectedAttribute({
            ...selectedAttribute,
            ...values,
        })

    return (
        <>
            <Divider />

            <FormControl fullWidth variant={'filled'}>
                <InputLabel>Allowed links</InputLabel>
                <Select
                    label="Allowed links"
                    value={allowed}
                    onChange={(event) => override({ allowed: event.target.value as AttributeLinkAllowedOptions })}
                >
                    {Object.values(AttributeLinkAllowedOptions)
                        .sort((a, b) => a.split('_').length - b.split('_').length) // Sort by number of items
                        .reverse()
                        .map((item) => (
                            <MenuItem key={item} value={item}>
                                {item.split('_').join(', ').toLocaleLowerCase()}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </>
    )
}
