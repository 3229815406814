import { Autocomplete, TextField } from '@mui/material'
import { AttributeAssetValue } from 'graphql/types'
import { AttributeValueEditProps } from './DataItemDetailsPanel'

export const AttributeAssetValueEdit = (props: AttributeValueEditProps) => {
    const { attribute, attributeValue, setAttributeValue, assets } = props

    if (attributeValue.__typename !== 'AttributeAssetValue') return null
    if (!attribute || attribute.__typename !== 'AttributeAsset') return null

    const { value, alt } = attributeValue

    const override = (values: Partial<AttributeAssetValue>) =>
        setAttributeValue({
            ...attributeValue,
            ...values,
        })

    return (
        <>
            <Autocomplete
                options={assets}
                getOptionLabel={(option) => option.label}
                defaultValue={assets.find((item) => item.id === value)}
                onChange={(_, value) => override({ value: value?.id })}
                renderInput={(params) => <TextField {...params} label={attribute.common.name} />}
            />
            <TextField
                label={'Alt text'}
                value={alt ?? ''}
                onChange={(event) => override({ alt: event.target.value })}
            />
        </>
    )
}
