import { FiberManualRecord, VisibilityOff } from '@mui/icons-material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box } from '@mui/material'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import React from 'react'
import colors from 'utils/colors'

export enum Status {
    PUBLISHED_NO_CHANGES = 'published_no_changes',
    PUBLISHED_WITH_CHANGES = 'published_with_changes',
    DEACTIVATED = 'deactivated',
    NOT_PUBLISHED = 'not_published',
}

export interface PageTreeEntry {
    id: string
    name: string
    status: Status
    subpages: PageTreeEntry[]
}

export interface PageTreeProps {
    pages: PageTreeEntry[]
    expanded: string[]
    onSetExpanded: (ids: string[]) => void
    selectedPage: string
    onPageSelect: (id: string) => void
}

const recursiveRender = (page: PageTreeEntry) => {
    const findColor = (): string => {
        let color: string = colors.black
        if (page.status === Status.PUBLISHED_WITH_CHANGES) color = colors.orange
        if (page.status === Status.NOT_PUBLISHED) color = colors.red
        return color
    }

    return (
        <Box display="flex" alignItems="flex-start" key={page.id} position="relative">
            <TreeItem
                nodeId={page.id}
                label={page.name}
                style={{ flex: 1, borderTop: '1px solid #ddd' }}
                ContentProps={{ style: { flex: 1, padding: '8px 0' } }}
            >
                {page.subpages.map((subpage) => recursiveRender(subpage))}
            </TreeItem>
            {page.status !== Status.PUBLISHED_NO_CHANGES && page.status !== Status.DEACTIVATED && (
                <FiberManualRecord
                    style={{ color: findColor(), position: 'absolute', right: 8, top: 16, height: 8, width: 8 }}
                />
            )}
            {page.status === Status.DEACTIVATED && (
                <VisibilityOff style={{ position: 'absolute', right: 4, top: 3, height: 12, width: 12 }} />
            )}
        </Box>
    )
}

const PageTree = (props: PageTreeProps): JSX.Element => {
    const { pages, onPageSelect, selectedPage, expanded, onSetExpanded } = props

    // const [expanded, setExpanded] = React.useState<string[]>([])

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        event.persist()
        const iconClicked = (event.target as Element).closest('.MuiTreeItem-iconContainer')
        if (iconClicked) {
            onSetExpanded(nodeIds)
        }
    }

    return (
        <Box style={{ overflowX: 'hidden', overflowY: 'auto' }} height="100%">
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                selected={selectedPage}
                expanded={expanded}
                onNodeSelect={(event: React.SyntheticEvent, nodeId: string) => {
                    event.persist()
                    const iconClicked = (event.target as Element).closest('.MuiTreeItem-iconContainer')
                    if (!iconClicked) {
                        onPageSelect(nodeId)
                    }
                }}
                onNodeToggle={handleToggle}
            >
                {pages.map((page) => {
                    return recursiveRender(page)
                })}
            </TreeView>
        </Box>
    )
}

export default PageTree
