import gql from 'graphql-tag'

export const LOAD_PAGE = gql`
    query loadPage($pageID: ID!) {
        loadPage(pageID: $pageID) {
            id
            parentPageIdentifier
            sortIndex
            deactivated
            createdAt
            updatedAt
            deletedAt
            historyVersions {
                ID
                pageID
                state
                contents
                createdAt
                updatedAt
                pageSettings {
                    htmlTitle
                    urlTitle
                    metaDescription
                    metaKeywords
                    robotsOption
                    includeInSitemapXML
                    pageRank
                    changeFrequency
                    language
                    translationID
                }
                updatedBy {
                    id
                    name
                }
            }
            currentVersion {
                ID
                pageID
                state
                contents
                createdAt
                updatedAt
                pageSettings {
                    htmlTitle
                    urlTitle
                    metaDescription
                    metaKeywords
                    robotsOption
                    includeInSitemapXML
                    pageRank
                    changeFrequency
                    language
                    translationID
                }
                updatedBy {
                    id
                    name
                }
            }
        }
    }
`
