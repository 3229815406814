export enum NavigationRoutes {
    PAGE_TREE = '/PageTree',
    BLOCK_LIST = '/BlockList',
    PAGE_EDIT = '/PageEdit',
    ASSETS = '/Assets',
    NOT_LOGGED_IN = '/NotLoggedIn',
    DATA_ITEMS = '/DataItems',
    PUBLISH = '/Publish',
    SETTINGS = '/Settings/:tab',
    WEBSITE_ADD = '/WebsiteAdd',
}
