import { Add } from '@mui/icons-material'
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { UseDataTypeTabsReturn } from 'pages/SettingsPage/DataTypeTab/useDataTypeTab'
import { Fragment } from 'react'
import colors from 'utils/colors'

const DataTypePanel = (props: UseDataTypeTabsReturn): JSX.Element => {
    const { dataTypes, onClickCreateType, onClickType, selectedType } = props

    return (
        <List>
            <Divider color={colors.midGray} />

            <ListItemButton selected={selectedType?.id === ''} onClick={onClickCreateType}>
                <ListItemIcon>
                    <Add />
                </ListItemIcon>
                <ListItemText primary="Create" />
            </ListItemButton>

            <Divider color={colors.midGray} />

            {dataTypes.map((item) => (
                <Fragment key={item.id}>
                    <ListItemButton selected={item.id === selectedType?.id} onClick={() => onClickType(item)}>
                        <ListItemText primary={item.name} />
                    </ListItemButton>
                    <Divider color={colors.midGray} />
                </Fragment>
            ))}
        </List>
    )
}

export default DataTypePanel
