import { Box } from '@mui/material'
import CMSHeader from 'components/CMSHeader/CMSHeader'
import DrawerGrid from 'components/DrawerGrid'
import { DrawerGridProps, DrawerWidth } from 'components/DrawerGrid/DrawerGrid'
import DataItemDetailsPanel from 'drawerPanels/DataItemDetailsPanel/DataItemDetailsPanel'
import DataItemEditPanel from 'drawerPanels/DataItemEditPanel/DataItemEditPanel'
import DataItemPanel from 'drawerPanels/DataItemPanel/DataItemPanel'
import { useStoreActions, useStoreState } from 'store/hooks'
import { useDataItemPage } from './UseDataItemPage'

export const DataItemPage = (): JSX.Element => {
    const drawerGridProps: DrawerGridProps = {
        primaryWidth: DrawerWidth.SMALL,
        secondaryWidth: DrawerWidth.MEDIUM,
        collapsible: true,
    }

    const websiteList = useStoreState((state) => state.model.websiteList)
    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    const setSelectedWebsite = useStoreActions((actions) => actions.model.setSelectedWebsite)

    const hookReturn = useDataItemPage()
    const { unpublishedItems, selectedItem, selectedType } = hookReturn

    drawerGridProps.primary = <DataItemPanel {...hookReturn} />

    if (selectedType && selectedItem) {
        drawerGridProps.secondary = (
            <DataItemDetailsPanel {...hookReturn} selectedType={selectedType} selectedItem={selectedItem} />
        )
    }

    if (selectedType)
        drawerGridProps.content = (
            <Box display="flex" flexDirection="column" flex={1} p={2} gap={1}>
                <DataItemEditPanel {...hookReturn} selectedType={selectedType} />
            </Box>
        )

    return (
        <>
            <CMSHeader
                unpublishedItems={unpublishedItems}
                selectedWebsite={selectedWebsite?.id ?? ''}
                websiteList={websiteList}
                onSelectWebsite={setSelectedWebsite}
            />
            <DrawerGrid {...drawerGridProps} />
        </>
    )
}
