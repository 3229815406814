import { Autocomplete, Divider, TextField } from '@mui/material'
import { AttributeCollection, DataType } from 'graphql/types'
import { AttributeEditProps } from './AttributeCommonEdit'

export type AttributeCollectionEditProps = AttributeEditProps & { subTypes: DataType[] }

export const AttributeCollectionEdit = (props: AttributeCollectionEditProps) => {
    const { selectedAttribute, setSelectedAttribute, subTypes } = props

    if (selectedAttribute.__typename !== 'AttributeCollection') return null

    const { dataType } = selectedAttribute

    const override = (values: Partial<AttributeCollection>) =>
        setSelectedAttribute({
            ...selectedAttribute,
            ...values,
        })

    return (
        <>
            <Divider />

            <Autocomplete
                options={subTypes}
                getOptionLabel={(option) => option.name}
                defaultValue={subTypes.find((item) => item.id === dataType)}
                onChange={(_, value) => override({ dataType: value?.id })}
                renderInput={(params) => <TextField {...params} label={'SubType'} />}
            />
        </>
    )
}
