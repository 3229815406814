import { Add, Delete } from '@mui/icons-material'
import { Box, Button, ClickAwayListener, Fab, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataType, DataTypeAttributeType, DataTypeCategory } from 'graphql/types'
import { UseDataTypeTabsReturn } from 'pages/SettingsPage/DataTypeTab/useDataTypeTab'
import { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import colors from 'utils/colors'
import { DataTypeAttributeRow } from './DataTypeAttributeRow'
import { FabSubButton } from './FabSubButton'

export type DataTypeEditPanelProps = Omit<UseDataTypeTabsReturn, 'selectedItem'> & { selectedItem: DataType }

const DataTypeEditPanel = (props: DataTypeEditPanelProps): JSX.Element | null => {
    const {
        selectedItem,
        setSelectedType,
        attributes,
        onClickSaveType,
        onClickDeleteType,
        selectedAttribute,
        onClickCreateAttribute,
        onDragEndAttribute,
        category,
    } = props

    const [expandFab, setExpandFab] = useState(false)

    const renderHeader = () => {
        return (
            <Box display="flex" flexDirection="row" flex={1} gap={1} paddingX={3} paddingY={1} bgcolor={colors.white}>
                <Box sx={{ flex: 0.5 }} />
                <Box sx={{ flex: 4.5 }}>
                    <Typography fontWeight={700} fontSize={'24px'}>
                        Identifier
                    </Typography>
                </Box>
                <Box sx={{ flex: 3 }}>
                    <Typography fontWeight={700} fontSize={'24px'}>
                        Name
                    </Typography>
                </Box>
                <Box sx={{ flex: 3 }}>
                    <Typography fontWeight={700} fontSize={'24px'}>
                        Type
                    </Typography>
                </Box>
                <Box width={category === DataTypeCategory.DATA_MODULE ? '80px' : '40px'} />
            </Box>
        )
    }

    return (
        <Box display="flex" flexDirection="column" flex={1} padding={2} gap={1}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mb={2}>
                <Tooltip title="Delete data type" placement="bottom">
                    <Button variant="contained" color={'error'} onClick={onClickDeleteType}>
                        <Delete />
                    </Button>
                </Tooltip>
                <Button variant="contained" onClick={onClickSaveType}>
                    Save Metadata
                </Button>
            </Stack>
            <TextField label={'ID'} value={selectedItem.id} disabled />
            <TextField
                label={'Identifier'}
                value={selectedItem.identifier}
                onChange={(change) => setSelectedType({ ...selectedItem, identifier: change.target.value })}
            />
            <TextField
                label={'Name'}
                value={selectedItem.name}
                onChange={(change) => setSelectedType({ ...selectedItem, name: change.target.value })}
            />
            <TextField
                label={'Description'}
                value={selectedItem.description}
                onChange={(change) => setSelectedType({ ...selectedItem, description: change.target.value })}
            />
            {selectedItem?.id && (
                <>
                    <DragDropContext onDragEnd={onDragEndAttribute}>
                        {renderHeader()}
                        <Box maxHeight={'calc(100vh - 353px)'} sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
                            <Droppable droppableId={'attributes'}>
                                {(provided) => (
                                    <span {...provided.droppableProps} ref={provided.innerRef}>
                                        {attributes.map((attribute, index) => (
                                            <Draggable key={attribute.id} draggableId={attribute.id} index={index}>
                                                {(provided) => (
                                                    <div
                                                        key={attribute.id}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <DataTypeAttributeRow {...props} item={attribute} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </span>
                                )}
                            </Droppable>
                        </Box>
                    </DragDropContext>
                    {attributes.length === 0 && (
                        <Box
                            display="flex"
                            flexDirection="row"
                            flex={1}
                            gap={1}
                            alignItems={'center'}
                            paddingX={3}
                            paddingY={1}
                            sx={{
                                bgcolor: colors.whiteHalfOpacity,
                                fontSize: '18px',
                            }}
                            justifyContent="center"
                        >
                            There are no attributes at the moment. You can add a new attribute by clicking the + button
                            in the bottom right corner.
                        </Box>
                    )}
                </>
            )}

            {!selectedAttribute && expandFab && (
                <Box
                    display={'flex'}
                    sx={{ position: 'fixed', bottom: 16, right: 88 }}
                    maxWidth={192}
                    flexWrap={'wrap'}
                    gap={1}
                >
                    {Object.values(DataTypeAttributeType)
                        .filter(
                            (item) =>
                                category !== DataTypeCategory.SUB_TYPE || item !== DataTypeAttributeType.COLLECTION,
                        )
                        .map((item) => (
                            <FabSubButton
                                key={item}
                                attributeType={item}
                                onClickCreateAttribute={onClickCreateAttribute}
                            />
                        ))}
                </Box>
            )}
            {!selectedAttribute && selectedItem.id && (
                <ClickAwayListener onClickAway={() => setExpandFab(false)}>
                    <Tooltip title="Add attribute" placement="top">
                        <Fab
                            color={expandFab ? 'secondary' : 'primary'}
                            aria-label="add"
                            sx={{ position: 'fixed', bottom: 16, right: 16 }}
                            onClick={() => setExpandFab(!expandFab)}
                        >
                            <Add />
                        </Fab>
                    </Tooltip>
                </ClickAwayListener>
            )}
        </Box>
    )
}

export default DataTypeEditPanel
