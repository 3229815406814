import { TextField } from '@mui/material'
import { AttributeTextValue } from 'graphql/types'
import { AttributeValueEditProps } from './DataItemDetailsPanel'

export const AttributeTextValueEdit = (props: AttributeValueEditProps) => {
    const { attribute, attributeValue, setAttributeValue } = props

    if (attributeValue.__typename !== 'AttributeTextValue') return null
    if (!attribute || attribute.__typename !== 'AttributeText') return null

    const { value } = attributeValue

    const override = (values: Partial<AttributeTextValue>) =>
        setAttributeValue({
            ...attributeValue,
            ...values,
        })

    return (
        <TextField
            label={attribute.common.name}
            value={value}
            onChange={(event) => override({ value: event.target.value })}
        />
    )
}
