import gql from 'graphql-tag'

export const DUPLICATE_PAGE = gql`
    mutation duplicatePage($input: DuplicatePageInput!) {
        duplicatePage(input: $input) {
            id
            parentPageIdentifier
            sortIndex
            deactivated
            currentVersion {
                ID
                pageID
                contents
                createdAt
                state
                updatedAt
                updatedBy {
                    id
                    name
                }
                pageSettings {
                    htmlTitle
                    urlTitle
                    metaDescription
                    metaKeywords
                    robotsOption
                    includeInSitemapXML
                    pageRank
                    changeFrequency
                    language
                    translationID
                }
            }
            historyVersions {
                ID
                pageID
                state
                contents
                createdAt
                updatedAt
                updatedBy {
                    id
                    name
                }
                pageSettings {
                    htmlTitle
                    urlTitle
                    robotsOption
                    includeInSitemapXML
                    pageRank
                    changeFrequency
                    language
                    translationID
                }
            }
        }
    }
`
