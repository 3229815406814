import { SnackbarType } from 'components/GeneralSnackbar/GeneralSnackbar'
import { AssetType } from 'graphql/types'

export type PageData = {
    regions?: PageElement[][]
    [x: string]: unknown
}

export type PageElement = {
    id: string
    type: string
    deactivated?: boolean
    data: PageData
}

export type LinkData = {
    url?: string
    hash?: string
    __qbd_pageId?: string
    __qbd_fileId?: string
    newWindow?: boolean
}

export type MediaData = {
    __qbd_fileId: string
    __qbd_altText: string
}

export enum PagePreviewEventType {
    DUPLICATE_ELEMENT = 'duplicateElement',
    DELETE_ELEMENT = 'deleteElement',
    SELECT_ELEMENT = 'selectElement',
    ADD_ELEMENT_BEFORE = 'addElementBefore',
    ADD_ELEMENT_AFTER = 'addElementAfter',
    COPY_ELEMENT = 'copyElement',
    CUT_ELEMENT = 'cutElement',
    EDIT_ELEMENT = 'editElement',
    TOGGLE_ELEMENT_DEACTIVATED = 'toggleElementDeactivated',
}

export type PagePreviewEvent = {
    type: PagePreviewEventType
    payload: any
}

export enum PagePreviewCommandType {
    UPDATE_CONTENT = 'updateContent',
    SET_OVERLAY = 'setOverlay',
    SET_EDIT_HELPERS = 'setEditHelpersVisible',
    REFRESH_ELEMENT_OVERLAYS = 'refreshElementOverlays',
    PREVIEW_READY = 'previewReady',
}

export type PagePreviewCommand = {
    type: PagePreviewCommandType
    payload: any
}

export type AddElementPosition = {
    elementID: string
    before: boolean
}

export type BlockData = {
    id: string
    identifier: string
    pageElements: PageElement[]
    createdAt: number
    updatedAt: number
}

export type Breakpoint = {
    name: string
    identifier: string
    editorCssWidth: string
    fromWidthPixels: number
}

export enum EditingDataType {
    PAGE,
    BLOCK,
}

export type CurrentlyEditingData = {
    id: string
    label: string
    identifier: string
    contents: string
    type: EditingDataType
}

export type FileUpload = {
    label: string
    type: string
    base64Data: string
}

export type SubField = {
    index: number
    field: string
}

export type FieldLocator = {
    fieldName: string
    subfield?: SubField
}

export type AssetSimplified = {
    id: string
    label: string
    type: AssetType
    url: string
}

export enum UnpublishedItemType {
    PAGE,
    BLOCK,
}

export enum UnpublishedItemState {
    EDIT,
    DEACTIVATED,
    UNPUBLISHED,
}

export type UnpublishedItem = {
    id: string
    label: string
    parentPageId?: string | null
    url?: string
    type: UnpublishedItemType
    state: UnpublishedItemState
}

export type LinkedLanguagePages = {
    pageId: string
    language: string
    url: string
}

export type LanguageVersion = {
    translationID: string
    linkedLanguagePages: LinkedLanguagePages[]
}

export type GeneralSnackbarConfig = {
    isOpen: boolean
    message: string
    type: SnackbarType
}

export type WebsiteOption = {
    id: string
    name: string
}
