import { Button } from '@mui/material'
import { DataTypeAttributeType } from 'graphql/types'

export interface FabSubButtonProps {
    attributeType: DataTypeAttributeType
    onClickCreateAttribute: (attributeType: DataTypeAttributeType) => void
}

export const FabSubButton = (props: FabSubButtonProps) => {
    const { attributeType, onClickCreateAttribute } = props

    return (
        <Button variant="contained" onClick={() => onClickCreateAttribute(attributeType)}>
            {/* Makes e.g. "TEXT" into "Text" */}
            {attributeType.charAt(0) + attributeType.toLowerCase().slice(1)}
        </Button>
    )
}
