import { Delete } from '@mui/icons-material'
import { Box, Checkbox, IconButton, Tooltip, Typography } from '@mui/material'
import colors from 'utils/colors'
import { DataItemUnion, LinkOption, getLinkOptionFromValue } from 'utils/dataType/types'
import { DataItemEditPanelProps } from './DataItemEditPanel'

export type DataItemEditPanelItemProps = DataItemEditPanelProps & { item: DataItemUnion }

export const DataItemEditPanelItem = (props: DataItemEditPanelItemProps) => {
    const { item, selectedItem, setSelectedItem, attributes, onClickDeleteItem, onTogglePublishItem, assets, pages } =
        props

    return (
        <Box
            key={item.id}
            display="flex"
            flexDirection="row"
            flex={1}
            gap={1}
            alignItems={'center'}
            paddingX={3}
            paddingY={1}
            onClick={() => setSelectedItem(item)}
            sx={{
                cursor: selectedItem === undefined ? 'pointer' : 'default',
                ':hover': { bgcolor: colors.primaryHalfOpacity },
                backgroundColor: selectedItem?.id === item.id ? colors.primary : colors.whiteHalfOpacity,
                transition: 'background-color 0.25s ease',
                position: 'relative',
            }}
        >
            <Box width={'120px'}>
                <Checkbox
                    checked={item.published}
                    onClick={(e) => {
                        e.stopPropagation()
                        onTogglePublishItem(item)
                    }}
                    sx={{ '&.Mui-checked': { color: colors.black06Opacity }, color: colors.black06Opacity }}
                />
            </Box>

            {attributes
                .filter((attribute) => attribute.common.visible)
                .map((attribute) => {
                    const attributeValue = item.values.find((value) => value.attributeID === attribute.id)

                    let text = attributeValue?.value.toString() ?? ''
                    if (attributeValue?.__typename === 'AttributeAssetValue')
                        text = assets.find((asset) => asset.id === attributeValue?.value)?.label ?? ''
                    else if (attributeValue?.__typename === 'AttributeCollectionValue')
                        text = `${attributeValue?.value.length} items`
                    else if (attributeValue?.__typename === 'AttributeLinkValue') {
                        const option = getLinkOptionFromValue(attributeValue.value)
                        if (option === LinkOption.INTERNAL) {
                            text =
                                pages.find((item) => item.id === attributeValue.value)?.currentVersion.pageSettings
                                    .htmlTitle ?? ''
                        } else if (option === LinkOption.EXTERNAL) {
                            text = attributeValue.value
                        } else {
                            assets.find((item) => item.id === attributeValue.value)?.label ?? ''
                        }
                    } else if (
                        attributeValue?.__typename === 'AttributeSelectionValue' &&
                        attribute.__typename === 'AttributeSelection'
                    )
                        text = attribute.options.find((option) => option.id === attributeValue.value)?.name ?? ''

                    return (
                        <Typography key={attribute.id} flex={1}>
                            {text}
                        </Typography>
                    )
                })}

            <Box display={'flex'} alignItems={'center'}>
                <Tooltip title="Delete item" placement="left">
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation()
                            onClickDeleteItem(item)
                        }}
                    >
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    )
}
